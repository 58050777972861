<template>
  <div class="">
        <div class="">
            <div>              
                <v-data-table
                  :headers="headers"
                  :items="fields_institucion.group_terminos.json_ano_institucion_terminos.json_ano_institucion_terminos"
                  sort-by="nu_id_padre"
                  class="elevation-1 mb-10"

                >
  
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Crear términos
                                </v-btn>

                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                        <div>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="contactos_temp.tx_frecuencia_pago"
                                            rules='required'
                                          >
                                                  <v-select
                                                    v-model="contactos_temp.tx_frecuencia_pago"
                                                    :items="list_frecuencia_pago"
                                                    item-text="tx_descripcion"
                                                    item-value="co_tipo"
                                                    class="input-group--focused text-lg"
                                                    placeholder='Frecuencia de pago'
                                                    :error-messages="errors"
                                                    label='Frecuencia de pago'
                                                    outlined
                                                    :disabled=!fields_institucion.isEditing
                                                  ></v-select>                      

                                          </validation-provider>                    
                                        </div> 
                                        <div>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="contactos_temp.fe_inicio_contrato"
                                            rules='required'
                                          >
                                                  <v-text-field
                                                    v-model="contactos_temp.fe_inicio_contrato"
                                                    outlined
                                                    class="input-group--focused text-lg"
                                                    :error-messages="errors"
                                                    label='Inicio del contrato'
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :disabled=!fields_institucion.isEditing                            
                                                  ></v-text-field>
                                          </validation-provider>                    
                                        </div> 
                                        <div>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="contactos_temp.tx_cantidad_estudiantes"
                                            rules='required'
                                          >
                                                  <v-text-field
                                                    v-model="contactos_temp.tx_cantidad_estudiantes"
                                                    outlined
                                                    class="input-group--focused text-lg"
                                                    :error-messages="errors"
                                                    label='Cantidad de estudiantes'
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :disabled=!fields_institucion.isEditing                            
                                                  ></v-text-field>
                                          </validation-provider>                    
                                        </div> 
                                        <div>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="contactos_temp.tx_precio_acordado"
                                            rules='required'
                                          >
                                                  <v-text-field
                                                    v-model="contactos_temp.tx_precio_acordado"
                                                    outlined
                                                    class="input-group--focused text-lg"
                                                    :error-messages="errors"
                                                    label='Precio acordado ($)'
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :disabled=!fields_institucion.isEditing                            
                                                  ></v-text-field>
                                          </validation-provider>                    
                                        </div> 
                                        <div>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="contactos_temp.tx_otras_condiciones"
                                            rules='required'
                                          >
                                            <v-textarea
                                                v-model="contactos_temp.tx_otras_condiciones"
                                                auto-grow
                                                v-bind="attrs"
                                                v-on="on"
                                                class="input-group--focused text-lg"
                                                :error-messages="errors"
                                                 label='Otras condiciones'
                                                outlined
                                                :disabled=!fields_institucion.isEditing
                                            ></v-textarea>                      
                                          </validation-provider>                    
                                        </div> 

                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Guardar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Esta seguro de borrar este registro?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Si</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      {{ icons.mdiPencil }}                                            
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDelete }}                                            
                      
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    <span>
                      No hay información
                    </span>
                  </template>
              </v-data-table>
            </div>

        </div>                  
       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { LIST_INSTITUCION } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'PersonaContacto',
  data () {
    return {
        muestra: true,
        list_especialidades: [],
        list_red_social: [
          {
            co_tipo: 'instagram',
            tx_descripcion: 'Instagram'
          },
          {
            co_tipo: 'twitter',
            tx_descripcion: 'Twitter'
          },
          {
            co_tipo: 'tiktok',
            tx_descripcion: 'Tiktok'
          },
        ],
        list_frecuencia_pago: [
          {
            co_tipo: 'mensual',
            tx_descripcion: 'Mensual'
          },
          {
            co_tipo: 'trimestral',
            tx_descripcion: 'Trimestral'
          },
          {
            co_tipo: 'semestral',
            tx_descripcion: 'Semestral'
          },
        ],
        list_nacionalidad: [
          {
            co_tipo: 'V',
            tx_descripcion: 'Venezolana'
          },
          {
            co_tipo: 'E',
            tx_descripcion: 'Extranjera'
          },
          {
            co_tipo: 'otro',
            tx_descripcion: 'Otros'
          },
        ],
        list_tipo_documento: [
          {
            co_tipo: 'CED',
            tx_descripcion: 'Cédula'
          },
          {
            co_tipo: 'PAS',
            tx_descripcion: 'Pasaporte'
          },
          {
            co_tipo: 'OTR',
            tx_descripcion: 'Otro'
          },

        ],
        contactos_temp: {
          tx_frecuencia_pago: '',
          fe_inicio_contrato: '',
          tx_cantidad_estudiantes: '',
          tx_precio_acordado: '',
          tx_otras_condiciones: '',
        },
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          json_institucion: '',
          json_especialidad: '',
        },  
        defaultItem: {
          json_institucion: [],
          json_especialidad: [],
          caracteristicas: {
            nombre: 'json_institucion',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        institucion_especialidad: {},
        items_data_table: [],   
        items_niveles: {
          json_ano_nivel: [],
          co_tipo: '',
          tx_etiqueta: '',
          tx_descripcion: '',
          nu_id_padre: '',
          json_nivel_padre: [],
        },   

        headers: [
            {
              text: 'Frecuancia de pago',
              align: 'start',
              sortable: false,
              value: 'tx_frecuencia_pago',
            },
            { text: 'Inicio contrato', value: 'fe_inicio_contrato' },        
            { text: 'Nro. estudiantes', value: 'tx_cantidad_estudiantes' },        
            { text: 'Precio ($)', value: 'tx_precio_acordado' },        
            { text: 'Acción', value: 'actions', sortable: false },
        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_institucion']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Item' : 'Editar Item'
    },

  },
  props: {
    send_datos: Object
  },
  components: {

  },    
  methods: {
      datos_muestra() {
        return this.medio
      },      
      close () {
        this.dialog = false
        this.$nextTick(() => {
          //this.cleanig()      
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.items_niveles[this.editedIndex], this.institucion_especialidad)
        } else {
          console.log('Save: ', this.contactos_temp)
          /*var aa = {
            json_institucion: this.institucion_especialidad.json_institucion,
            json_especialidad: this.institucion_especialidad.json_especialidad
          }
          */
          //this.fields_institucion.json_institucion.json_especialidad = this.institucion_especialidad.json_especialidad
          for (const property in this.contactos_temp) {
              console.log('item: ', property, this.contactos_temp[property])
          }
          
          //this.fields_institucion.group_terminos.json_ano_institucion_terminos.json_ano_institucion_terminos.push(this.contactos_temp)
          
          this.fields_institucion.group_terminos.json_ano_institucion_terminos.json_ano_institucion_terminos.push({
            tx_frecuencia_pago: this.contactos_temp.tx_frecuencia_pago,
            fe_inicio_contrato: this.contactos_temp.fe_inicio_contrato,
            tx_cantidad_estudiantes: this.contactos_temp.tx_cantidad_estudiantes,
            tx_precio_acordado: this.contactos_temp.tx_precio_acordado,
            tx_otras_condiciones: this.contactos_temp.tx_otras_condiciones
          })
          for (const property in this.contactos_temp) {
              this.contactos_temp[property] = ''
          }    
              console.log('Campos: ',  this.fields_institucion.group_terminos.json_ano_institucion_terminos.json_ano_institucion_terminos)
          //this.fields_institucion.group_niveles.json_ano_nivel.json_ano_nivel = this.items_data_table

          //this.fields_institucion.group_niveles.json_institucion.json_institucion.push(this.institucion_especialidad.json_institucion)
        }
        this.close()
      },    
      initialize () {
        this.institucion_especialidad = this.defaultItem
      },

      editItem (item) {
        //this.searchInstitucion(item.json_institucion.nu_id_institucion)
        this.editedIndex = this.fields_institucion.group_niveles.json_ano_nivel.json_ano_nivel.indexOf(item)
        const claves_primarias = {}
        claves_primarias.tx_nombre = 'tx_nombre'
        for (const property in item) {
            //console.log('item: ', property)
            if (claves_primarias[property] != property) {
              this.fields_institucion.group_niveles[property][property] = item[property]
            }               
        }

        this.dialog = true
      },
      cleanig () {
        const claves_primarias = {}
        claves_primarias.json_ano_nivel = 'json_ano_nivel'

        for (const property in this.fields_institucion.group_terminos) {
            if (claves_primarias[property] != property) {
              const myArray = property.split("_"); 
              if (myArray[0] === 'JSON') {
                this.fields_institucion.group_terminos[property][property] = []
              } else {
                this.fields_institucion.group_terminos[property][property] = ''
              }          
            }          
        }

      },
      deleteItem (item) {
        this.editedIndex = this.fields_institucion.group_terminos.json_ano_institucion_terminos.json_ano_institucion_terminos.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_institucion.group_terminos.json_ano_institucion_terminos.json_ano_institucion_terminos.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    searchEspecialidad() {
          this.list_especialidades = JSON.parse(this.institucion_especialidad.json_institucion.json_especialidad)
    },    
    searchInstitucion(q) {
      console.log('searchInstitucion 1: ', q)
      this.$store.dispatch(LIST_INSTITUCION, {nu_id_institucion: q}).then(response => {
          console.log('searchInstitucion 2: ', response.rest.items)
          this.list_especialidades = JSON.parse(response.rest.items[0].json_especialidad)
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },  
    remove (item) {
      var index = this.institucion_especialidad.json_especialidad.findIndex(i => i.nu_id_especialidad === item.nu_id_especialidad);
      if (index >= 0) this.institucion_especialidad.json_especialidad.splice(index, 1)
    },    

  },
  
  mounted() {
    //this.searchPerfiles();
    if (this.send_datos.operacion === 'EDITAR') {
        //this.fields_institucion.group_niveles.json_institucion.json_institucion = JSON.parse(this.send_datos.usuario.json_institucion)
        //this.fields_institucion.group_niveles.json_especialidad.json_especialidad = JSON.parse(this.send_datos.usuario.json_especialidad)
          // for (var prop in this.send_datos.usuario) {
          //     if(typeof this.fields_institucion[prop] === 'object') {
          //           if (this.fields_institucion[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_institucion[prop][prop] = JSON.parse(this.send_datos.usuario[prop])
          //           } else {
          //             this.fields_institucion[prop][prop] = this.send_datos.usuario[prop]
          //           }
          //     } else {
          //           this.fields_institucion[prop][prop] = this.send_datos.usuario[prop]
          //     }
          // }
    } else {
        //this.limpiar()
        //this.list_especialidades = JSON.parse(this.fields_institucion.group_niveles.json_institucion.json_institucion[0].json_especialidad)
        //this.fields_institucion.group_niveles.json_ano_nivel.json_ano_nivel = []
        this.fields_institucion.isEditing = true
    }           
  },
    
}
</script>