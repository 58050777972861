<template>
  <div class="p-2">
        <ValidationObserver ref="datos_basicos">
          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  name="fields_indicadores.group_datos_basicos.co_indicador_logro.co_indicador_logro"
                  :rules=fields_indicadores.group_datos_basicos.co_indicador_logro.caracteristicas.required
                >
                  <v-text-field
                    :label=fields_indicadores.group_datos_basicos.co_indicador_logro.caracteristicas.label
                    v-model="fields_indicadores.group_datos_basicos.co_indicador_logro.co_indicador_logro"
                    outlined
                    class="input-group--focused text-lg pt-2"
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!fields_indicadores.isEditing"
                  ></v-text-field>
                </validation-provider>                    
                </template>
                <span>{{fields_indicadores.group_datos_basicos.co_indicador_logro.caracteristicas.tooltip  }} </span>
              </v-tooltip>                

          </div>
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors }"
                name="fields_indicadores.group_datos_basicos.tx_nombre_indicador_logro.tx_nombre_indicador_logro"
                :rules=fields_indicadores.group_datos_basicos.tx_nombre_indicador_logro.caracteristicas.required
              >
                <v-text-field
                  :label=fields_indicadores.group_datos_basicos.tx_nombre_indicador_logro.caracteristicas.label
                  v-model="fields_indicadores.group_datos_basicos.tx_nombre_indicador_logro.tx_nombre_indicador_logro"
                  outlined
                  class="input-group--focused text-lg pt-2"
                  :error-messages="errors"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!fields_indicadores.isEditing"
                ></v-text-field>
              </validation-provider>                    
              </template>
              <span>{{fields_indicadores.group_datos_basicos.tx_nombre_indicador_logro.caracteristicas.tooltip  }} </span>
            </v-tooltip>                
          </div>
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors }"
                name="fields_indicadores.group_datos_basicos.tx_descripcion_indicador_logro.tx_descripcion_indicador_logro"
                :rules=fields_indicadores.group_datos_basicos.tx_descripcion_indicador_logro.caracteristicas.required
              >
              <v-textarea
                v-model="fields_indicadores.group_datos_basicos.tx_descripcion_indicador_logro.tx_descripcion_indicador_logro"
                outlined
                class="input-group--focused text-lg pt-2"
                :error-messages="errors"
                :label=fields_indicadores.group_datos_basicos.tx_descripcion_indicador_logro.caracteristicas.label
                v-bind="attrs"
                v-on="on"
                :disabled=!fields_indicadores.isEditing                            
              ></v-textarea>
              </validation-provider>                    
              </template>
              <span>{{fields_indicadores.group_datos_basicos.tx_nombre_indicador_logro.caracteristicas.tooltip  }} </span>
            </v-tooltip>                
          </div>



          </ValidationObserver>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {  MUESTRA_PERSONA, LIST_TIPO_PERSONA  } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'InstitucionDatosBasicos',
  data () {
    return {
        modal: false,                 
        search:null,
        isLoading: false,
        tipo_persona: [],
        list_tipo_institucion: [
          {
            co_tipo: 'pub',
            tx_descripcion: 'Pública'
          },
          {
            co_tipo: 'priv',
            tx_descripcion: 'Privada'
          },
        ],

    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      search () {
        // Items have already been loaded
        if (this.itemsRepresentante.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        this.$store.dispatch(MUESTRA_PERSONA, {}).then(response => {
            this.codRepresentante = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        }).finally(() => (this.isLoading = false))      

      },          
  },     
  computed: {
    ...mapGetters(['fields_indicadores']),
      listaTipoPersona () {
          return this.tipo_persona.map(entry => {
              const Description = entry.tx_tipo_persona.length > this.descriptionLimit
              ? entry.tx_tipo_persona.slice(0, this.descriptionLimit) + '...'
              : entry.tx_tipo_persona

              return Object.assign({}, entry, { Description })
          })
      },             

    fecha_reg_max() {
       return new Date().toISOString().slice(0,10);

    },  
  },
  props: {
    send_datos: Object
  },
  components: {

  },    
  methods: {
    searchTipoPersona(q) {
    this.$store.dispatch(LIST_TIPO_PERSONA, {q: q}).then(response => {
        console.log('searchTipoPersona: ', response )
        this.tipo_persona = response.rest.items
    }, error => {
        this.$store.commit(SET_ERROR, error.statusText)
    })      
    },      
    async guardar () {
        const isValid = await this.$refs.datos_basicos.validate()
        if (!isValid) {
            return true
        }
        this.$emit("guardar_institucion_datos_basicos", JSON.stringify(this.fields_indicadores.group_datos_basicos));
    },              
    async cancelar () {
        this.$notify({
        group: 'error',
        type: 'Info',
        title: 'Mensaje importante',
        classes: 'w-full text-lg',
        text: 'Operación cancelada!'
        });      
    //this.loading4 = true 
        this.$emit("guardar_enfermedad_actual", JSON.stringify(this.fields_historia_clinica));

    this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

    }, 
          




  },
  
  mounted() {
      //this.searchTipoPersona({})
      //this.fields_indicadores.isEditing  = true
  },
    
}
</script>