<template>
  <div class="">
        <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
          <NotificacionInterna :mensaje="mensaje" />  
        </div>
      <div>
        <lapsosHeader titulo="Lapso / Momento / Periodo" /> 
      </div>  

        <div class="">
            <div>
                <v-data-table
                  :headers="headers"
                  :items="fields_lapsos.group_datos_basicos.json_group_datos_basicos.json_group_datos_basicos"
                  sort-by="calories"
                  class="elevation-1 mb-10"

                >
                
                  <!-- <template v-slot:item.json_perfil="{ item }">
                      <div v-for="(item_esp, index) in item.json_perfil" :key="index">

                      {{ item_esp.tx_nombre_perfil }}
                      </div>
                  </template>      -->
                  <!--                   
                  <template v-slot:item.tx_nombre_lapso="{ item }">
                      {{ item.tx_nombre_lapso.tx_razon_social }}
                  </template>      -->
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Crear
                                </v-btn>

                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                      >
                                            <validation-provider
                                              v-slot="{ errors }"
                                              name="fields_lapsos.group_datos_basicos.nu_id_ano_escolar.nu_id_ano_escolar"
                                              :rules=fields_lapsos.group_datos_basicos.nu_id_ano_escolar.caracteristicas.required
                                            >
                                              <v-autocomplete
                                              v-model='fields_lapsos.group_datos_basicos.nu_id_ano_escolar.nu_id_ano_escolar'
                                              :items="list_anos"
                                              :error-messages="errors"
                                              chips
                                              outlined
                                              color="blue-grey lighten-2"
                                              :label=fields_lapsos.group_datos_basicos.nu_id_ano_escolar.caracteristicas.label
                                              item-text="tx_nombre_ano"
                                              item-value="nu_id_ano_escolar"
                                              :disabled=!fields_lapsos.isEditing                            
                                              >
                                              <template v-slot:selection="data">
                                                  <v-chip
                                                  v-bind="data.attrs"
                                                  :input-value="data.selected"
                                                  close
                                                  @click="data.select"
                                                  @click:close="remove(data.item)"
                                                  >
                                                  {{ data.item.tx_nombre_ano }}
                                                  </v-chip>
                                              </template>

                                              </v-autocomplete>
                                            </validation-provider>                                           
                                      </v-col>
                                      
                                      <v-col
                                        cols="12"
                                      >
                                          <v-tooltip right>
                                            <template v-slot:activator="{ on, attrs }">

                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="fields_lapsos.group_datos_basicos.co_lapso.co_lapso"
                                            :rules=fields_lapsos.group_datos_basicos.co_lapso.caracteristicas.required
                                          >
                                          <v-text-field
                                            v-model="fields_lapsos.group_datos_basicos.co_lapso.co_lapso"
                                            :label=fields_lapsos.group_datos_basicos.co_lapso.caracteristicas.label
                                            outlined
                                            class="input-group--focused text-lg pt-2"
                                            :error-messages="errors"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!fields_lapsos.isEditing"
                                          ></v-text-field>
                                          </validation-provider>     
                                            </template>
                                            <span>{{ fields_lapsos.group_datos_basicos.co_lapso.caracteristicas.tooltip  }} </span>
                                          </v-tooltip>                
                                      </v-col>

                                      <v-col
                                        cols="12"
                                      >
                                      <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_lapsos.group_datos_basicos.tx_nombre_lapso.tx_nombre_lapso"
                                          :rules=fields_lapsos.group_datos_basicos.tx_nombre_lapso.caracteristicas.required
                                        >
                                          <v-text-field
                                            :label=fields_lapsos.group_datos_basicos.tx_nombre_lapso.caracteristicas.label
                                            v-model="fields_lapsos.group_datos_basicos.tx_nombre_lapso.tx_nombre_lapso"
                                            outlined
                                            class="input-group--focused text-lg pt-2"
                                            :error-messages="errors"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!fields_lapsos.isEditing"
                                          ></v-text-field>
                                        </validation-provider>                    
                                        </template>
                                        <span>{{fields_lapsos.group_datos_basicos.tx_nombre_lapso.caracteristicas.tooltip  }} </span>
                                      </v-tooltip>                
                                      </v-col>
                                      <v-col
                                        cols="12"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_lapsos.group_datos_basicos.fe_inicio_lapso.fe_inicio_lapso"
                                          :rules=fields_lapsos.group_datos_basicos.fe_inicio_lapso.caracteristicas.required
                                        >
                                              <v-dialog
                                                ref="dialog_fecha_inicio"
                                                v-model="modal_dialogo_inicial"
                                                :return-value.sync="fields_lapsos.group_datos_basicos.fe_inicio_lapso.fe_inicio_lapso"
                                                persistent
                                                width="290px"
                                              >
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-text-field
                                                    v-model="fields_lapsos.group_datos_basicos.fe_inicio_lapso.fe_inicio_lapso"
                                                    :label=fields_lapsos.group_datos_basicos.fe_inicio_lapso.caracteristicas.label
                                                    :error-messages="errors"
                                                    :prepend-icon=icons.mdiCalendar
                                                    v-bind="attrs"
                                                    v-on="on"
                                                  ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                  v-model="fields_lapsos.group_datos_basicos.fe_inicio_lapso.fe_inicio_lapso"
                                                  :date-format="date => new Date(date).toDateString()" 
                                                  :formatted-value.sync="fields_lapsos.group_datos_basicos.fe_inicio_lapso.fe_inicio_lapso"
                                                  locale="es"
                                                >
                                                  <v-spacer></v-spacer>
                                                  <v-btn
                                                    text
                                                    color="primary"
                                                    @click="modal_dialogo_inicial = false"
                                                  >
                                                    Cancelar
                                                  </v-btn>
                                                  <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.dialog_fecha_inicio.save(fields_lapsos.group_datos_basicos.fe_inicio_lapso.fe_inicio_lapso)"
                                                  >
                                                    Guardar
                                                  </v-btn>
                                                </v-date-picker>
                                              </v-dialog>
                                          </validation-provider>                    
                                      </v-col>
                                      <v-col
                                        cols="12"
                                      >
                                            <validation-provider
                                              v-slot="{ errors }"
                                              name="fields_lapsos.group_datos_basicos.fe_fin_lapso.fe_fin_lapso"
                                              :rules=fields_lapsos.group_datos_basicos.fe_fin_lapso.caracteristicas.required
                                            >
                                              <v-dialog
                                                ref="dialog_fecha_final"
                                                v-model="modal_dialogo_final"
                                                :return-value.sync="fields_lapsos.group_datos_basicos.fe_fin_lapso.fe_fin_lapso"
                                                persistent
                                                width="290px"
                                              >
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-text-field
                                                    v-model="fields_lapsos.group_datos_basicos.fe_fin_lapso.fe_fin_lapso"
                                                    :label=fields_lapsos.group_datos_basicos.fe_fin_lapso.caracteristicas.label
                                                    :error-messages="errors"
                                                    :prepend-icon=icons.mdiCalendar
                                                    v-bind="attrs"
                                                    v-on="on"
                                                  ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                  v-model="fields_lapsos.group_datos_basicos.fe_fin_lapso.fe_fin_lapso"
                                                  :date-format="date => new Date(date).toDateString()" 
                                                  :formatted-value.sync="fields_lapsos.group_datos_basicos.fe_fin_lapso.fe_fin_lapso"
                                                  locale="es"
                                                >
                                                  <v-spacer></v-spacer>
                                                  <v-btn
                                                    text
                                                    color="primary"
                                                    @click="modal_dialogo_final = false"
                                                  >
                                                    Cancelar
                                                  </v-btn>
                                                  <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.dialog_fecha_final.save(fields_lapsos.group_datos_basicos.fe_fin_lapso.fe_fin_lapso)"
                                                  >
                                                    Guardar
                                                  </v-btn>
                                                </v-date-picker>
                                              </v-dialog>
                                            </validation-provider>     
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Guardar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Desea borrar este registro?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Guardar</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      {{ icons.mdiPencil }}                                            
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDelete }}                                            
                      
                    </v-icon>

                  </template>
                  <template v-slot:no-data>
                    <span>
                      No hay información
                    </span>
                  </template>
              </v-data-table>
            </div>
            <div class="text-center">
            <button @click="cerrar()" class="text-center w-full font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-muted-hover cursor-pointer py-2 px-6 mr-1">
              Cerrar
            </button>                                                        
            </div>

        </div>                  
       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import LapsosHeader from "@/components/comunes/HeaderModulo.vue";

import {  CREATE_LAPSO, LIST_LAPSO, LIST_ANOS } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'Lapsos',
  data () {
    return {
      list_anos: [],
        modal_dialogo_inicial:false,
        modal_dialogo_final:false,
        mensaje: {
          satisfactorio: {
            status: false,
            texto: ''
          },
          nosatisfactorio: {
            status: false,
            texto: ''
          },
        },
        list_perfiles: [],
        fields_lapsos_temp: [],
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          tx_nombre_lapso: '',
          json_perfil: '',
        },  

        headers: [
            {
              text: 'Código',
              align: 'start',
              sortable: false,
              value: 'co_lapso',
            },
            { text: 'Denominacion', value: 'tx_nombre_lapso' },        
            { text: 'Fecha inicio', value: 'fe_inicio_lapso' },        
            { text: 'Fecha fin', value: 'fe_fin_lapso' },        
            { text: 'Actions', value: 'actions', sortable: false },
        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_lapsos']),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  props: {
    send_datos: Object
  },
  components: {
    NotificacionInterna,
    LapsosHeader
  },    
  methods: {
      cerrar() {
      this.$emit("guardar_lapsos", JSON.stringify(this.fields_lapsos));
      },
      searchAnoEscolar(q) {
        this.$store.dispatch(LIST_ANOS, {nu_id_ano_escolar: q}).then(response => {
            this.list_anos = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })
      },
      searchLapsos(q) {
        this.$store.dispatch(LIST_LAPSO, {nu_id_ano_escolar: q}).then(response => {
            this.fields_lapsos.group_datos_basicos.json_group_datos_basicos.json_group_datos_basicos = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })
      },
      fecha_cita_min() {
      //const [month, day, year] = date.split('/')
      //return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`

        //return new Date().toISOString().slice(0,10);
      },    
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.lapso_perfil.json_perfil = []
          this.lapso_perfil.json_lapso = {}
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.tx_nombre_lapso = Object.assign({}, this.fields_lapsos.group_datos_basicos.tx_nombre_lapso.tx_nombre_lapso)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.fields_lapsos.group_datos_basicos.tx_nombre_lapso.tx_nombre_lapso[this.editedIndex], this.lapso_perfil)
        } else {
          console.log('this.this.send_datos: ', this.send_datos)
          this.fields_lapsos.group_datos_basicos.nu_id_institucion.nu_id_institucion =  this.send_datos.institucion.nu_id_institucion
          this.fields_lapsos.group_datos_basicos.json_group_datos_basicos.json_group_datos_basicos.push({            
            nu_id_institucion: this.send_datos.institucion.nu_id_institucion,
            co_lapso: this.fields_lapsos.group_datos_basicos.co_lapso.co_lapso,
            tx_nombre_lapso: this.fields_lapsos.group_datos_basicos.tx_nombre_lapso.tx_nombre_lapso,
            fe_inicio_lapso: this.fields_lapsos.group_datos_basicos.fe_inicio_lapso.fe_inicio_lapso,
            fe_fin_lapso: this.fields_lapsos.group_datos_basicos.fe_fin_lapso.fe_fin_lapso,
          })

        }
        this.return_lapso(JSON.stringify(this.fields_lapsos))
        this.close()
      },    
      initialize () {
        this.lapso_perfil = this.defaultItem
      },

      editItem (item) {
        this.editedIndex = this.fields_lapsos.group_datos_basicos.tx_nombre_lapso.tx_nombre_lapso.indexOf(item)
        this.lapso_perfil = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.fields_lapsos.group_datos_basicos.json_group_datos_basicos.json_group_datos_basicos.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_lapsos.group_datos_basicos.json_group_datos_basicos.json_group_datos_basicos.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    remove (item) {
      var index = this.lapso_perfil.json_perfil.findIndex(i => i.nu_id_perfil === item.nu_id_perfil);
      if (index >= 0) this.lapso_perfil.json_perfil.splice(index, 1)
    },    

    // Paso #2
    return_lapso(msg) {
        //this.buscarPersona('params')
        var guardar = JSON.parse(msg);

        const formData = new FormData()
        formData.append('lapsos', JSON.stringify(guardar))

    // desde aca optimizar
        this.mensaje.satisfactorio.status = false
        this.mensaje.nosatisfactorio.status = false

        //formData = JSON.parse(JSON.stringify(guardar.formData))

        formData.append('oper', 'REGISTRO')

        this.$store.dispatch(CREATE_LAPSO, formData).then(datos => { 
              this.mensaje.satisfactorio.texto = "Registro de la lapso " + JSON.parse(datos.datos_lapso).tx_nombre_lapso + " fue satisfactorio"
              this.mensaje.satisfactorio.status = true
              
              setTimeout(() => {
                    this.isLoading = false
                //    this.$emit("guardar_usuario", JSON.stringify(this.fields_lapso));
                    console.log('message emit from child component01: ', datos)
                    this.mensaje.satisfactorio.status = false
                    this.$notify({
                      group: 'generic',
                      type: 'success',
                      title: 'Mensaje importante',
                      classes: 'w-full text-lg',
                      text: 'Datos  creado satisfactoriamente!'
                    });      

                },3000)                  
                
                this.$emit("actualizar_datos_lapso", JSON.stringify(guardar));              
                
                //this.$refs.tabs_ubigeo_tabJustify.click();

              
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
              this.mensaje.nosatisfactorio.texto = "Registro de lapso cancelado"
              this.mensaje.nosatisfactorio.status = true
              this.ocultar_pestana()
              this.mostrar_pestana('collapseMotivo')
              setTimeout(() => {
                  this.mensaje.nosatisfactorio.status = false
                  },3000);             
              console.log("Cancelar: " + guardar.btn_guardar);

          })  

      /// hasta aca optimizar
        
    },    


  },
  
  mounted() {
    this.searchAnoEscolar();
    this.searchLapsos()
    if (this.send_datos.operacion === 'EDITAR') {
      //console.log('tx_nombre_lapso: ', JSON.parse(this.fields_lapsos.group_datos_basicos.tx_nombre_lapso.tx_nombre_lapso))
        //this.fields_lapsos.group_datos_basicos.tx_nombre_lapso.tx_nombre_lapso = JSON.parse(this.send_datos.usuario.tx_nombre_lapso)
        //this.fields_lapsos.group_datos_basicos.json_perfil.json_perfil = JSON.parse(this.send_datos.usuario.json_perfil)
          // for (var prop in this.send_datos.usuario) {
          //     if(typeof this.fields_lapsos[prop] === 'object') {
          //           if (this.fields_lapsos[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_lapsos[prop][prop] = JSON.parse(this.send_datos.usuario[prop])
          //           } else {
          //             this.fields_lapsos[prop][prop] = this.send_datos.usuario[prop]
          //           }
          //     } else {
          //           this.fields_lapsos[prop][prop] = this.send_datos.usuario[prop]
          //     }
          // }
    } else {
        //this.limpiar()
        //this.fields_lapsos.group_datos_basicos.json_perfil.json_perfil = []
        this.fields_lapsos.isEditing = true
    }           
  },
    
}
</script>