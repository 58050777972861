var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('ValidationObserver',{ref:"datos_basicos"},[_c('div',[_c('validation-provider',{attrs:{"name":"fields_institucion.group_datos_basico.json_tipo_institucion.json_tipo_institucion","rules":_vm.fields_institucion.group_datos_basico.json_tipo_institucion.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.list_tipo_institucion,"loading":_vm.isLoading,"error-messages":errors,"hide-no-data":"","hide-selected":"","item-text":"tx_descripcion","item-value":"co_tipo","label":_vm.fields_institucion.group_datos_basico.json_tipo_institucion.caracteristicas.label,"placeholder":_vm.fields_institucion.group_datos_basico.json_tipo_institucion.caracteristicas.placeholder,"prepend-icon":"","return-object":"","outlined":"","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.fields_institucion.group_datos_basico.json_tipo_institucion.json_tipo_institucion),callback:function ($$v) {_vm.$set(_vm.fields_institucion.group_datos_basico.json_tipo_institucion, "json_tipo_institucion", $$v)},expression:"fields_institucion.group_datos_basico.json_tipo_institucion.json_tipo_institucion"}})]}}])})],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_institucion.group_datos_basico.tx_rif.tx_rif","rules":_vm.fields_institucion.group_datos_basico.tx_rif.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('A-#########-#'),expression:"'A-#########-#'"}],staticClass:"input-group--focused text-lg pt-2",attrs:{"outlined":"","error-messages":errors,"label":_vm.fields_institucion.group_datos_basico.tx_rif.caracteristicas.label,"disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.fields_institucion.group_datos_basico.tx_rif.tx_rif),callback:function ($$v) {_vm.$set(_vm.fields_institucion.group_datos_basico.tx_rif, "tx_rif", $$v)},expression:"fields_institucion.group_datos_basico.tx_rif.tx_rif"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_institucion.group_datos_basico.tx_rif.caracteristicas.tooltip)+" ")])])],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_institucion.group_datos_basico.tx_razon_social.tx_razon_social","rules":_vm.fields_institucion.group_datos_basico.tx_razon_social.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"auto-grow":"","error-messages":errors,"label":_vm.fields_institucion.group_datos_basico.tx_razon_social.caracteristicas.label,"outlined":"","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.fields_institucion.group_datos_basico.tx_razon_social.tx_razon_social),callback:function ($$v) {_vm.$set(_vm.fields_institucion.group_datos_basico.tx_razon_social, "tx_razon_social", $$v)},expression:"fields_institucion.group_datos_basico.tx_razon_social.tx_razon_social"}},'v-textarea',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_institucion.group_datos_basico.tx_razon_social.caracteristicas.tooltip)+" ")])])],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_institucion.group_datos_basico.tx_nombre_comercial.tx_nombre_comercial","rules":_vm.fields_institucion.group_datos_basico.tx_nombre_comercial.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"auto-grow":"","error-messages":errors,"label":_vm.fields_institucion.group_datos_basico.tx_nombre_comercial.caracteristicas.label,"outlined":"","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.fields_institucion.group_datos_basico.tx_nombre_comercial.tx_nombre_comercial),callback:function ($$v) {_vm.$set(_vm.fields_institucion.group_datos_basico.tx_nombre_comercial, "tx_nombre_comercial", $$v)},expression:"fields_institucion.group_datos_basico.tx_nombre_comercial.tx_nombre_comercial"}},'v-textarea',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_institucion.group_datos_basico.tx_nombre_comercial.caracteristicas.tooltip)+" ")])])],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_institucion.group_datos_basico.co_plantel.co_plantel","rules":_vm.fields_institucion.group_datos_basico.co_plantel.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"outlined":"","error-messages":errors,"label":_vm.fields_institucion.group_datos_basico.co_plantel.caracteristicas.label,"disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.fields_institucion.group_datos_basico.co_plantel.co_plantel),callback:function ($$v) {_vm.$set(_vm.fields_institucion.group_datos_basico.co_plantel, "co_plantel", $$v)},expression:"fields_institucion.group_datos_basico.co_plantel.co_plantel"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_institucion.group_datos_basico.co_plantel.caracteristicas.tooltip)+" ")])])],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_institucion.group_datos_basico.tx_zona_educativa.tx_zona_educativa","rules":_vm.fields_institucion.group_datos_basico.tx_zona_educativa.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"outlined":"","error-messages":errors,"label":_vm.fields_institucion.group_datos_basico.tx_zona_educativa.caracteristicas.label,"disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.fields_institucion.group_datos_basico.tx_zona_educativa.tx_zona_educativa),callback:function ($$v) {_vm.$set(_vm.fields_institucion.group_datos_basico.tx_zona_educativa, "tx_zona_educativa", $$v)},expression:"fields_institucion.group_datos_basico.tx_zona_educativa.tx_zona_educativa"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_institucion.group_datos_basico.tx_zona_educativa.caracteristicas.tooltip)+" ")])])],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_institucion.group_datos_basico.tx_distrito_escolar.tx_distrito_escolar","rules":_vm.fields_institucion.group_datos_basico.tx_distrito_escolar.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"outlined":"","error-messages":errors,"label":_vm.fields_institucion.group_datos_basico.tx_distrito_escolar.caracteristicas.label,"disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.fields_institucion.group_datos_basico.tx_distrito_escolar.tx_distrito_escolar),callback:function ($$v) {_vm.$set(_vm.fields_institucion.group_datos_basico.tx_distrito_escolar, "tx_distrito_escolar", $$v)},expression:"fields_institucion.group_datos_basico.tx_distrito_escolar.tx_distrito_escolar"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_institucion.group_datos_basico.tx_distrito_escolar.caracteristicas.tooltip)+" ")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }