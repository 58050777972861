<template>
  <div class="">
      <div>
        
        <IndicadorLogroHeader :titulo="titulo" /> 
      </div>  
    <div class="md:flex md:items-start">
      <div class="w-full">
          <ul class="nav nav-tabs flex flex-col  justify-end md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4" id="tabs-tabFill"
            role="tablist">
            <li class="nav-item flex-auto text-center" @click="handleClick('group_ano_escolar_01')" role="presentation">
              <a href="#tabsIndicadoresFill" class="
                nav-link
                w-full
                block
                font-medium
                text-xs
                leading-tight
                uppercase
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                my-2
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
                active
              " id="tabs-indicadores-tabFill" data-bs-toggle="pill" data-bs-target="#tabsIndicadoresFill" role="tab"
                aria-controls="tabsIndicadoresFill" aria-selected="false">Indicadores de Logro</a>
            </li>
            <li class="nav-item flex-auto text-center" role="presentation">
              <a href="#tabs-wizardLogroFill" class="
                nav-link
                w-full
                block
                font-medium
                text-xs
                leading-tight
                uppercase
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                my-2
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
              " id="tabs-wizardLogro-tabFill" data-bs-toggle="pill" data-bs-target="#tabs-wizardLogroFill" role="tab"
                aria-controls="tabs-wizardLogroFill" aria-selected="true">Wizard</a>
            </li>
            <li class="nav-item flex-auto text-center" @click="cerrar()" role="presentation">
              <a href="#tabs-cerrarFill" class="
                nav-link
                w-full
                block
                font-medium
                text-xs
                leading-tight
                uppercase
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                my-2
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
              " id="tabs-cerrar-tabFill" data-bs-toggle="pill" data-bs-target="#tabs-cerrarFill" role="tab"
                aria-controls="tabs-cerrarFill" aria-selected="true">Salir</a>
            </li>
          </ul>
          <div class="tab-content" id="tabs-tabContentFill">
            <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
              <NotificacionInterna :mensaje="mensaje" />  
            </div>
            <div class="tab-pane fade  show active" id="tabsIndicadoresFill" role="tabpanel" aria-labelledby="tabs-indicadores-tabFill">
                  <IndicadorLogroListar titulo="" @guardar_indicador_logro="return_wizardLogro" :send_datos="send_datos"/>        
            </div>
            <div class="tab-pane fade" id="tabs-wizardLogroFill" role="tabpanel" aria-labelledby="tabs-wizardLogro-tabFill">
              <div class=" border-2 rounded-md shadow-md">
                <Wizard titulo="" @actualizar_datos_indicadores="return_wizardLogro" :send_datos="send_datos"/>
              </div>
            </div>
          </div>        
      </div>
    </div>
</div>

</template>

<script>
import { mapGetters } from 'vuex'
import { CREATE_INDICADOR_LOGRO_WIZARD, LIST_INDICADORES_LOGRO } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import IndicadorLogroListar from "@/views/configuracion/IndicadoresLogro/IndicadorLogroListar.vue";
import Wizard from "@/views/configuracion/IndicadoresLogro/Wizard/Wizard";


import IndicadorLogroHeader from "@/components/comunes/HeaderModulo.vue";

const moment = require('moment');

  export default {
    name: 'IndicadorLogroOpciones',
    data() {
        return {
          titulo: 'Indicadores de Logro',
          list_indicadores_logro: [],
          tabs: {
            group_usuario:{
              active: '',
              class: ''
            },
          },
          fields_indicadores_temp: '',
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
          locales:{
            grupo_ano_escolar: [],
            fields_indicadores: ''
          },
          isHistoria: false,
          pestana: {
            collapseDatosBasico: '',
            collapseHistoriaClinica: '',      
            collapseMotivo: '',
            collapseEnfermedadActual: '',
            collapseAntecedentesEnfermedadActual: '',
            collapseAntecedentesPersonales: '',
            collapseAntecedentesFamiliares: '',
            collapseExamenFisico: ''            
          }
        }
    },    
    computed: {
        ...mapGetters(['fields_indicadores','fields_persona']),
        hoy() {
            const today = new Date();
            var fecha2 = moment(today);
            return fecha2.format('DD-MM-YYYY');
        },       


    },    
    props: {
        send_datos: Object,
    },
    components: {
      NotificacionInterna,
      Wizard,
      IndicadorLogroListar,
      IndicadorLogroHeader,
    },
    methods: {
      searchIndicadorLogros(q) {
        this.$store.dispatch(LIST_INDICADORES_LOGRO, {q: q}).then(response => {
            this.send_datos.list_indicadores_logro = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })      
      },
       async limpiar () {
            for (const property in this.fields_indicadores) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_indicadores[property]) {
                          this.fields_indicadores[property][property_01][property_01] = ''
                      }
                  }
            }
            for (const property in this.fields_persona) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_persona[property]) {
                          this.fields_persona[property][property_01][property_01] = ''
                      }
                  }
            }



        },
        handleClick(e) {
          this.locales.grupo_ano_escolar = []
          this.locales.grupo_ano_escolar.push(this.fields_indicadores.[e]) 
        },     
        cerrar(msg) {
          console.log('actualizar_datos_competencias: Actualizar')
          this.$emit("guardar_indicador_logro", msg);
        },    
        ocultar_pestana() {
          for (var property in this.pestana) {
                this.pestana[property] = 'hide'
            }
        },
        mostrar_pestana(obj) {
                this.pestana[obj] = 'show'
        },
        // Paso #2
        return_wizardLogro(msg) {
            var guardar = JSON.parse(msg);
            const formData = new FormData()
            formData.append('indicador_logro', JSON.stringify(guardar))

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            //formData = JSON.parse(JSON.stringify(guardar.formData))

            formData.append('oper', 'REGISTRO')

            this.$store.dispatch(CREATE_INDICADOR_LOGRO_WIZARD, formData).then(datos => { 
                  this.mensaje.satisfactorio.texto = "Registro del indicador " + JSON.parse(datos.datos_indicador_logro).tx_nombre_indicador_logro + " fue satisfactorio"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_indicadores));
                        console.log('message emit from child component01: ', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos consulta creados satisfactoriamente!'
                        });      

                    },3000)                  
                    this.searchIndicadorLogros()
                    this.$emit("actualizar_datos_indicadores", JSON.stringify(guardar));              
                    this.$refs.tabsIndicadoresFill.click();

                  
              }, error => {
                  this.$store.commit(SET_ERROR, error.statusText)
                  this.mensaje.nosatisfactorio.texto = "Registro de prototipo cancelado"
                  this.mensaje.nosatisfactorio.status = true
                  this.ocultar_pestana()
                  this.mostrar_pestana('collapseMotivo')
                  setTimeout(() => {
                      this.mensaje.nosatisfactorio.status = false
                      },3000);             
                  console.log("Cancelar: " + guardar.btn_guardar);

              })  

          /// hasta aca optimizar
            
        },    



    },
    mounted() {
      this.searchIndicadorLogros()
    },         

    
  }
</script>

<style lang="scss" scoped>

</style>