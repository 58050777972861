var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{},[_c('div',[_c('v-data-table',{staticClass:"elevation-1 mb-10",attrs:{"headers":_vm.headers,"items":_vm.fields_institucion.group_terminos.json_ano_institucion_terminos.json_ano_institucion_terminos,"sort-by":"nu_id_padre"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Crear términos ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('div',[_c('validation-provider',{attrs:{"name":"contactos_temp.tx_frecuencia_pago","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_frecuencia_pago,"item-text":"tx_descripcion","item-value":"co_tipo","placeholder":"Frecuencia de pago","error-messages":errors,"label":"Frecuencia de pago","outlined":"","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.contactos_temp.tx_frecuencia_pago),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "tx_frecuencia_pago", $$v)},expression:"contactos_temp.tx_frecuencia_pago"}})]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"contactos_temp.fe_inicio_contrato","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"outlined":"","error-messages":errors,"label":"Inicio del contrato","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.contactos_temp.fe_inicio_contrato),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "fe_inicio_contrato", $$v)},expression:"contactos_temp.fe_inicio_contrato"}},'v-text-field',_vm.attrs,false),_vm.on))]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"contactos_temp.tx_cantidad_estudiantes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"outlined":"","error-messages":errors,"label":"Cantidad de estudiantes","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.contactos_temp.tx_cantidad_estudiantes),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "tx_cantidad_estudiantes", $$v)},expression:"contactos_temp.tx_cantidad_estudiantes"}},'v-text-field',_vm.attrs,false),_vm.on))]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"contactos_temp.tx_precio_acordado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"outlined":"","error-messages":errors,"label":"Precio acordado ($)","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.contactos_temp.tx_precio_acordado),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "tx_precio_acordado", $$v)},expression:"contactos_temp.tx_precio_acordado"}},'v-text-field',_vm.attrs,false),_vm.on))]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"contactos_temp.tx_otras_condiciones","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"auto-grow":"","error-messages":errors,"label":"Otras condiciones","outlined":"","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.contactos_temp.tx_otras_condiciones),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "tx_otras_condiciones", $$v)},expression:"contactos_temp.tx_otras_condiciones"}},'v-textarea',_vm.attrs,false),_vm.on))]}}])})],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Esta seguro de borrar este registro?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("No")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Si")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v(" No hay información ")])]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }