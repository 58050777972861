<template>
  <div class="">
      <div>
        
        <InstitucionHeader :titulo="titulo" /> 
      </div>  
    <div class="md:flex md:items-start">
      <div class="w-full">
          <ul class="nav nav-tabs flex flex-col  justify-end md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4" id="tabs-tabFill"
            role="tablist">
            <li class="nav-item flex-auto text-center" @click="handleClick('group_ano_escolar')" role="presentation">
              <a href="#tabs-institucionFill" class="
                nav-link
                w-full
                block
                font-medium
                text-xs
                leading-tight
                uppercase
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                my-2
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
                active
              " id="tabs-institucion-tabFill" data-bs-toggle="pill" data-bs-target="#tabs-institucionFill" role="tab"
                aria-controls="tabs-institucionFill" aria-selected="false">Instituciones</a>
            </li>
            <li
            v-if='filteredPermisos("create_institucion_configuracion") === true' 
            class="nav-item flex-auto text-center" role="presentation">
              <a href="#tabs-wizardFill" class="
                nav-link
                w-full
                block
                font-medium
                text-xs
                leading-tight
                uppercase
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                my-2
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
              " id="tabs-wizard-tabFill" data-bs-toggle="pill" data-bs-target="#tabs-wizardFill" role="tab"
                aria-controls="tabs-wizardFill" aria-selected="true">Wizard</a>
            </li>

          </ul>
          <div class="tab-content" id="tabs-tabContentFill">
            <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
              <NotificacionInterna :mensaje="mensaje" />  
            </div>
            <div class="tab-pane fade  show active" id="tabs-institucionFill" role="tabpanel" aria-labelledby="tabs-institucion-tabFill">
                  <InstitucionListar titulo="" @guardar_usuario_datos_basicos="return_wizard" :send_datos="send_datos"/>        
            </div>
            <div class="tab-pane fade" id="tabs-wizardFill" role="tabpanel" aria-labelledby="tabs-wizard-tabFill">
              <div class=" border-2 rounded-md shadow-md">
                <Wizard titulo="" @actualizar_datos_institucion="return_wizard" :send_datos="send_datos"/>
              </div>
            </div>
          </div>        
      </div>
    </div>
</div>

</template>

<script>
import { mapGetters } from 'vuex'
import { CREATE_INSTITUCION_WIZARD } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import Wizard from "@/views/configuracion/Institucion/Wizard/Wizard";
//import Institucion from "@/views/configuracion/Institucion.vue";
import InstitucionListar from "@/views/configuracion/Institucion/InstitucionListar.vue";


import InstitucionHeader from "@/components/comunes/HeaderModulo.vue";

const moment = require('moment');

  export default {
    name: 'InstitucionOpciones',
    data() {
        return {
          titulo: 'Instituciones',
          tabs: {
            group_usuario:{
              active: '',
              class: ''
            },
          },
          fields_institucion_temp: '',
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
          locales:{
            grupo_ano_escolar: [],
            fields_institucion: ''
          },
          isHistoria: false,
          pestana: {
            collapseDatosBasico: '',
            collapseHistoriaClinica: '',      
            collapseMotivo: '',
            collapseEnfermedadActual: '',
            collapseAntecedentesEnfermedadActual: '',
            collapseAntecedentesPersonales: '',
            collapseAntecedentesFamiliares: '',
            collapseExamenFisico: ''            
          }
        }
    },    
    computed: {
        ...mapGetters(['fields_institucion','fields_persona','currentUser']),
        hoy() {
            const today = new Date();
            var fecha2 = moment(today);
            return fecha2.format('DD-MM-YYYY');
        },       


    },    
    props: {
        send_datos: Object,
    },
    components: {
      NotificacionInterna,
      Wizard,
      InstitucionListar,
      InstitucionHeader,
    },
    methods: {
    filteredPermisos (item) {
      var index = this.currentUser.autorizacion.findIndex(i => i === item);
        console.log('param: ', index, item)
      if (index >= 0) {
        return true
      } else {
        return false
      }
    },    

       async limpiar () {
            for (const property in this.fields_institucion) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_institucion[property]) {
                          this.fields_institucion[property][property_01][property_01] = ''
                      }
                  }
            }
            for (const property in this.fields_persona) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_persona[property]) {
                          this.fields_persona[property][property_01][property_01] = ''
                      }
                  }
            }



        },
        handleClick(e) {
          this.locales.grupo_ano_escolar = []
          this.locales.grupo_ano_escolar.push(this.fields_institucion.[e]) 
        },     
        cerrar(msg) {
          console.log('actualizar_datos_institucion: Actualizar')
          this.$emit("actualizar_datos_institucion", msg);
        },    
        ocultar_pestana() {
          for (var property in this.pestana) {
                this.pestana[property] = 'hide'
            }
        },
        mostrar_pestana(obj) {
                this.pestana[obj] = 'show'
        },
        // Paso #2
        return_wizard(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);

            const formData = new FormData()
            formData.append('institucion', JSON.stringify(guardar))

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            //formData = JSON.parse(JSON.stringify(guardar.formData))

            formData.append('oper', 'REGISTRO')

            this.$store.dispatch(CREATE_INSTITUCION_WIZARD, formData).then(datos => { 
                  this.mensaje.satisfactorio.texto = "Registro de la institución " + JSON.parse(datos.datos_institucion).tx_razon_social + " fue satisfactorio"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_institucion));
                        console.log('message emit from child component01: ', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos creados satisfactoriamente!'
                        });      

                    },3000)                  
                    
                    this.$emit("actualizar_datos_institucion", JSON.stringify(guardar));              
                    
                    //this.$refs.tabs_ubigeo_tabJustify.click();

                  
              }, error => {
                  this.$store.commit(SET_ERROR, error.statusText)
                  this.mensaje.nosatisfactorio.texto = "Registro de prototipo cancelado"
                  this.mensaje.nosatisfactorio.status = true
                  this.ocultar_pestana()
                  this.mostrar_pestana('collapseMotivo')
                  setTimeout(() => {
                      this.mensaje.nosatisfactorio.status = false
                      },3000);             
                  console.log("Cancelar: " + guardar.btn_guardar);

              })  

          /// hasta aca optimizar
            
        },    



    },
    mounted() {
    },         

    
  }
</script>

<style lang="scss" scoped>

</style>