<template>
    <div>
        <div class="pl-2 pr-2">
            <div class="pl-4 pt-2 pr-2  mb-2 rounded-lg bg-skin-search text-black" >
              <v-text-field
                v-model="search"
                clearable
                :prepend-inner-icon=icons.mdiMagnify
                label="Buscador"
              ></v-text-field>
              </div>
              <div class="rounded-lg">

                  <v-data-table
                    :headers="headers"
                    :items="send_datos.list_instituciones"
                    :items-per-page="5"
                    :expanded.sync="expanded"   
                    item-key="nu_id_usuario"
                    show-expand                                     
                    :search="search"     
                    :loading="isLoading"
                    loading-text="Cargando... Por favor espere"                    
                    class="elevation-1"
                  >
                      <template v-slot:item.actions="{ item }">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              small
                              class="mr-2"
                              v-if='filteredPermisos("read_escalavalores") === true'
                              @click="escala_valores(item)"
                            >
                              {{ icons.mdiBookPlusMultiple }}
                            </v-icon>
                          </template>
                          <span>Escala de valores</span>
                        </v-tooltip>

                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              small
                              class="mr-2"
                              v-if='filteredPermisos("read_indicadoreslogro") === true'
                              @click="indicadores_logro(item)"
                            >
                              {{ icons.mdiBookPlusMultiple }}
                            </v-icon>
                          </template>
                          <span>Indicadores de logro </span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              small
                              class="mr-2"
                              v-if='filteredPermisos("read_lapsos") === true'
                              @click="lapsos(item)"
                            >
                              {{ icons.mdiBookPlusMultiple }}
                            </v-icon>
                          </template>
                          <span>Lapsos/periodos/momentos</span>
                        </v-tooltip>
                        <v-icon
                          small
                          class="mr-2"
                          @click="editar(item)"
                        >
                          {{ icons.mdiPencil }}        
                        </v-icon>
        <!--                <v-icon
                          small
                          @click="mostrar_foto(getImgUrl(JSON.parse(item.tx_fotos_json)))"
                        >
                          {{ icons.mdiImageSearchOutline }}                
                        </v-icon>
        -->                
                      </template>
                        <!-- name -->
                        <template #[`item.tx_razon_social`]="{item}">
                          <div class="d-flex flex-column">
                            <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.tx_razon_social }}</span>
            <!--                <small>{{ item.tx_nombres }}</small> -->
                          </div>
                        </template>          
                        <template #[`item.json_pais`]="{item}">
                          <div class="d-flex flex-column">
                                {{ JSON.parse(item.json_pais).tx_denominacion }}
            <!--                <small>{{ item.tx_nombres }}</small> -->
                          </div>
                        </template>        
                        <template v-slot:item.co_estatus="{ item }">
                          <div v-if='filteredPermisos("ventas_akdemia") === true'>
                            <span
                              v-if="item.co_estatus.replace(/ /g, '') === 'VENTA'"
                              @click="update_estatus('ADMINISTRACION', item)"
                              class="px-4 py-2 rounded-full text-gray-500 bg-red-200 font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
                              {{ item.co_estatus }}
                            </span>                                                      
                          </div>
                          <div v-if='filteredPermisos("administracion_akdemia") === true'>

                            <span
                              v-if="item.co_estatus.replace(/ /g, '') === 'ADMINISTRACION'"
                              @click="update_estatus('SATISFACCION', item)"
                              class="px-4 py-2 rounded-full text-gray-500 bg-yellow-200 font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
                              {{ item.co_estatus }}
                            </span>                          
                          </div>
                          <div v-if='filteredPermisos("satisfaccion_akdemia") === true'>
                            <span
                              v-if="item.co_estatus.replace(/ /g, '') === 'SATISFACCION'"
                              class="px-4 py-2 rounded-full text-gray-500 bg-green-200 font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
                              {{ item.co_estatus }}
                            </span>                          
                          </div>

                        </template>                          
                        <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length">
                              <tr>
                                <td class="font-bold pr-3">
                                  Nombre comercial:
                                </td>
                                <td>
                                  {{ item.tx_nombre_comercial }}
                                </td>
                                </tr>
                                <tr>
                                  <td class="font-bold">
                                    Dirección:
                                  </td>
                                  <td>
                                    {{ item.tx_direccion }}
                                  </td>
                                </tr>
                              <div class="" v-for="(item_01, index) in JSON.parse(item.json_ano_institucion_terminos)" :key="index">   
                                <span class="font-bold">Cantidad de estudiantes: </span>
                                  {{ item_01.tx_cantidad_estudiantes }}

                                </div>
                              <span class="font-bold">Contactos: </span>
                              <div class="" v-for="(item_01, index) in JSON.parse(item.json_ano_institucion_personal_contacto)" :key="index">   
                                      <span class="pl-9">
                                  <tr class="ml-9">
                                    <td>
                                        {{ item_01.tx_nombres }}
                                        {{ item_01.tx_apellidos }}
                                    </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {{ item_01.tx_telefono_celular }},
                                        {{ item_01.tx_telefono_local }}
                                      </td>
                                        
                                    </tr>
                                    <tr>
                                      <td>
                                        {{ item_01.tx_correo_electronico }}
                                      </td>
                                        
                                    </tr>
                                      </span>
                                </div>

                              <!--<div class="" v-for="(item_01, index) in JSON.parse(item.json_institucion_perfil)" :key="index">      
                                {{ item_01.tx_nombre_perfil }}
                              </div>
                              -->                            
                          </td>
                        </template>                                                  
                        
                  </v-data-table>
              </div>
    </div>

        <v-dialog  v-if="dialogEstatus" :value="true" @input="dialogEstatus = false" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Desea pasar a {{ estatus }}?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeUpdate">No</v-btn>
              <v-btn color="blue darken-1" text @click="updateItemConfirm">Si</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
                       
        <v-dialog
          max-width="800px"
          transition="dialog-bottom-transition"  
            v-if="dialogIndicadorLogro" :value="true" @input="dialogIndicadorLogro = false"                   
            >
            <v-card>
              <v-container>

                <div class="w-full mx-auto my-auto">

                <IndicadorLogroOpciones @guardar_indicador_logro="return_indicador_logro" :send_datos="send_datos" titulo="titulo"/>
                </div>

              </v-container>

            </v-card>
          </v-dialog>              
        <v-dialog
            max-width="700px"
            transition="dialog-bottom-transition" 
            v-if="dialogLapsos" :value="true" @input="dialogLapsos = false"                   
            >
            <v-card>
              <v-container>

                <div class="w-full mx-auto my-auto">

                <Lapsos @guardar_lapsos="return_lapsos" :send_datos="send_datos" titulo="Lapsos / Indicadores"/>
                </div>

              </v-container>

            </v-card>
          </v-dialog>              
        <v-dialog
            max-width="700px"
            transition="dialog-bottom-transition" 
            v-if="dialogEscalaValores" :value="true" @input="dialogEscalaValores = false"                   
            >
            <v-card>
              <v-container>

                <div class="w-full mx-auto my-auto">

                <EscalaValores @guardar_escala_valores="return_escala_valores" :send_datos="send_datos" titulo="Escala de Valores"/>
                </div>

              </v-container>

            </v-card>
          </v-dialog>              

    </div>
</template>

<script>
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { mapGetters } from 'vuex'
import { reactive  } from "@vue/composition-api"
//import {   SET_ERROR } from '@/store/mutations.type'
import { UPDATE_INSTITUCION, LIST_INSTITUCION, CREATE_INDICADOR_LOGRO, LIST_INDICADORES_LOGRO,LIST_COMPETENCIAS } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import IndicadorLogroOpciones from "@/views/configuracion/IndicadoresLogro/IndicadorLogroOpciones";
import Lapsos from "@/views/configuracion/Lapsos/Lapsos";
import EscalaValores from "@/views/configuracion/Calificacion/EscalaValores/EscalaValores";

export default {
    name: 'InstitucionListar',
    data() {
        return {
          isLoading: false,          
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
            dialogIndicadorLogro: false,
            dialogLapsos: false,
            dialogEscalaValores: false,
            dialogEstatus: false,
            dialog: false, 
            search: '',
            estatus: '',
            item_update: '',
            titulo: 'Institución',
            expanded: [],            
            headers: [
              {
                text: 'Id',
                align: 'start',
                sortable: false,
                value: 'nu_id_institucion',
              },          
              {
                text: 'Razón Social',
                sortable: true,
                value: 'tx_razon_social',
              },          
              { 
                text: 'Ubicación', 
                value: 'json_pais' 
              },
              { 
                text: 'Estatus', 
                value: 'co_estatus' 
              },
              { 
                text: 'Acción', 
                value: 'actions', 
                sortable: false 
              },  
              { 
                text: '', 
                value: 'data-table-expand' 
              },                        

            ],            
        }
    },
    props: {
        send_datos: []

    },
    computed: {
        ...mapGetters(['fields_institucion','currentUser']),
        
    },    
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  

        return {
        icons,
        }
    },
    methods: {
    filteredPermisos (item) {
      var index = this.currentUser.autorizacion.findIndex(i => i === item);
        console.log('param: ', index, item)
      if (index >= 0) {
        return true
      } else {
        return false
      }
    },    
    searchIndicadorLogros(q) {
        this.$store.dispatch(LIST_INDICADORES_LOGRO, {q: q}).then(response => {
            this.send_datos.list_indicadores_logro = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })      
      },      
    searchLapsos(q) {
        this.$store.dispatch(LIST_COMPETENCIAS, {q: q}).then(response => {
            this.send_datos.list_lapsos = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })      
      },      
    indicadores_logro (id) {
      console.log('Editar id: ', id) // someValue
      this.send_datos.institucion = id
      this.dialogIndicadorLogro = true
    },
    lapsos (id) {
      console.log('Editar id: ', id) // someValue
      this.send_datos.institucion = id
      this.dialogLapsos = true
    },
    escala_valores (id) {
      console.log('Editar id: ', id) // someValue
      this.send_datos.institucion = id
      this.dialogEscalaValores = true
    },
   update_estatus (status ,id) {
      console.log('Editar id: ', status,  id) // someValue
      this.estatus = status
      this.item_update = id
      this.dialogEstatus = true
    },
   closeUpdate() {
      this.dialogEstatus = false
    },
   updateItemConfirm () {
      console.log('this.item_update:', this.item_update.nu_id_institucion)

        const formData = new FormData()
        formData.append('institucion',JSON.stringify({
            nu_id_institucion: this.item_update.nu_id_institucion,
            co_estatus: this.estatus
            }) 
        )
    // desde aca optimizar
        this.mensaje.satisfactorio.status = false
        this.mensaje.nosatisfactorio.status = false

        //formData = JSON.parse(JSON.stringify(guardar.formData))

        formData.append('oper', 'UPDATE')

        this.$store.dispatch(UPDATE_INSTITUCION, formData).then(datos => { 
              this.mensaje.satisfactorio.texto = "Actualiación  fue satisfactorio"
              this.mensaje.satisfactorio.status = true
              
              setTimeout(() => {
                    this.isLoading = false
                //    this.$emit("guardar_usuario", JSON.stringify(this.fields_institucion));
                    console.log('message emit from child component01: ', datos)
                    this.mensaje.satisfactorio.status = false
                    this.$notify({
                      group: 'generic',
                      type: 'success',
                      title: 'Mensaje importante',
                      classes: 'w-full text-lg',
                      text: 'Información actualizada satisfactoriamente!'
                    });      

                },3000)                  
                              
                //this.$refs.tabs_ubigeo_tabJustify.click();

              
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
              this.mensaje.nosatisfactorio.texto = "Registro de prototipo cancelado"
              this.mensaje.nosatisfactorio.status = true
              this.ocultar_pestana()
              this.mostrar_pestana('collapseMotivo')
              setTimeout(() => {
                  this.mensaje.nosatisfactorio.status = false
                  },3000);             

          })  

          /// hasta aca optimizar



      this.dialogEstatus = false
    },
    editar (id) {
      console.log('Editar id: ', id) // someValue
      this.send_datos.list_instituciones = id
      this.send_datos.operacion = 'EDITAR'
      this.dialog = true
    },
    return_indicador_logro(msg) {    
            if (!msg) {
              this.dialogIndicadorLogro = false
              return
            }
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);

            const formData = new FormData()
            formData.append('indicador_logro', JSON.stringify(guardar))

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            //formData = JSON.parse(JSON.stringify(guardar.formData))

            formData.append('oper', 'REGISTRO')

            this.$store.dispatch(CREATE_INDICADOR_LOGRO, formData).then(datos => { 
                  this.mensaje.satisfactorio.texto = "Registro de la institución " + JSON.parse(datos.datos_institucion).tx_razon_social + " fue satisfactorio"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_institucion));
                        console.log('message emit from child component01: ', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos creados satisfactoriamente!'
                        });      

                    },3000)                  
                    
                    this.$emit("actualizar_datos_institucion", JSON.stringify(guardar));              
                    
                    //this.$refs.tabs_ubigeo_tabJustify.click();

                  
              }, error => {
                  this.$store.commit(SET_ERROR, error.statusText)
                  this.mensaje.nosatisfactorio.texto = "Registro de prototipo cancelado"
                  this.mensaje.nosatisfactorio.status = true
                  this.ocultar_pestana()
                  this.mostrar_pestana('collapseMotivo')
                  setTimeout(() => {
                      this.mensaje.nosatisfactorio.status = false
                      },3000);             
                  console.log("Cancelar: " + guardar.btn_guardar);

              })  

          /// hasta aca optimizar


    },    
    return_lapsos(msg) {    
      //this.buscarPersona('params')
        this.dialogLapsos = false
        console.log("Cancelar: " + msg);
    },    
    return_escala_valores(msg) {    
      //this.buscarPersona('params')
        this.dialogEscalaValores = false
        console.log("Cancelar: " + msg);
    },    
    searchInstituciones(q) {
        this.isLoading = true
        this.$store.dispatch(LIST_INSTITUCION, {q: q}).then(response => {
            this.list_instituciones = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        }).finally(() => (
        setTimeout(() => {
            this.isLoading = false
          },3000)               
        //this.e1 = 1
        //this.limpiar_campos_clientes()
        ))             
     },
  
    }, 
    components: {
      IndicadorLogroOpciones,
      EscalaValores,
      Lapsos
    },
    mounted() {
      this.searchInstituciones({})
      this.searchIndicadorLogros('params')
      this.searchLapsos('params')
      //this.list_instituciones_01 = this.list_instituciones; 
      //console.log('Especialidads: ', this.list_instituciones)
    },
    watch: {
    },    


}
</script>