var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c('validation-provider',{attrs:{"name":"fields_institucion.group_datos_ubigeo.json_pais.json_pais","rules":_vm.fields_institucion.group_datos_ubigeo.json_pais.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.listaPaises,"loading":_vm.isLoading,"error-messages":errors,"hide-no-data":"","hide-selected":"","item-text":"tx_denominacion","item-value":"nu_id_pais","label":_vm.fields_institucion.group_datos_ubigeo.json_pais.caracteristicas.label,"placeholder":_vm.fields_institucion.group_datos_ubigeo.json_pais.caracteristicas.placeholder,"prepend-icon":"","outlined":"","return-object":"","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.fields_institucion.group_datos_ubigeo.json_pais.json_pais),callback:function ($$v) {_vm.$set(_vm.fields_institucion.group_datos_ubigeo.json_pais, "json_pais", $$v)},expression:"fields_institucion.group_datos_ubigeo.json_pais.json_pais"}})]}}])}),_c('validation-provider',{attrs:{"name":"fields_institucion.group_datos_ubigeo.json_estado.json_estado","rules":_vm.fields_institucion.group_datos_ubigeo.json_estado.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.listaEntidades,"loading":_vm.isLoading,"error-messages":errors,"hide-no-data":"","hide-selected":"","item-text":"tx_desc_entidad","item-value":"tx_cod_entidad","label":_vm.fields_institucion.group_datos_ubigeo.json_estado.caracteristicas.label,"placeholder":_vm.fields_institucion.group_datos_ubigeo.json_estado.caracteristicas.placeholder,"prepend-icon":"","return-object":"","outlined":"","disabled":!_vm.fields_institucion.isEditing},on:{"change":_vm.searchMunicipio},model:{value:(_vm.fields_institucion.group_datos_ubigeo.json_estado.json_estado),callback:function ($$v) {_vm.$set(_vm.fields_institucion.group_datos_ubigeo.json_estado, "json_estado", $$v)},expression:"fields_institucion.group_datos_ubigeo.json_estado.json_estado"}})]}}])}),_c('validation-provider',{attrs:{"name":"fields_institucion.group_datos_ubigeo.json_municipio.json_municipio","rules":_vm.fields_institucion.group_datos_ubigeo.json_municipio.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.codigosMunicipio,"loading":_vm.isLoading,"error-messages":errors,"hide-no-data":"","hide-selected":"","item-text":"tx_desc_municipio","item-value":"tx_cod_municipio","label":_vm.fields_institucion.group_datos_ubigeo.json_municipio.caracteristicas.label,"placeholder":_vm.fields_institucion.group_datos_ubigeo.json_municipio.caracteristicas.placeholder,"prepend-icon":"","return-object":"","outlined":"","disabled":!_vm.fields_institucion.isEditing},on:{"change":_vm.searchParroquia},model:{value:(_vm.fields_institucion.group_datos_ubigeo.json_municipio.json_municipio),callback:function ($$v) {_vm.$set(_vm.fields_institucion.group_datos_ubigeo.json_municipio, "json_municipio", $$v)},expression:"fields_institucion.group_datos_ubigeo.json_municipio.json_municipio"}})]}}])}),_c('validation-provider',{attrs:{"name":"fields_institucion.group_datos_ubigeo.json_parroquia.json_parroquia","rules":_vm.fields_institucion.group_datos_ubigeo.json_parroquia.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.codigosParroquia,"loading":_vm.isLoading,"error-messages":errors,"hide-no-data":"","hide-selected":"","item-text":"tx_desc_parroquia","item-value":"tx_cod_parroquia","label":_vm.fields_institucion.group_datos_ubigeo.json_parroquia.caracteristicas.label,"placeholder":_vm.fields_institucion.group_datos_ubigeo.json_parroquia.caracteristicas.placeholder,"prepend-icon":"","outlined":"","return-object":"","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.fields_institucion.group_datos_ubigeo.json_parroquia.json_parroquia),callback:function ($$v) {_vm.$set(_vm.fields_institucion.group_datos_ubigeo.json_parroquia, "json_parroquia", $$v)},expression:"fields_institucion.group_datos_ubigeo.json_parroquia.json_parroquia"}})]}}])}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_institucion.group_datos_ubigeo.tx_direccion.tx_direccion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"auto-grow":"","error-messages":errors,"label":_vm.fields_institucion.group_datos_ubigeo.tx_direccion.caracteristicas.label,"outlined":"","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.fields_institucion.group_datos_ubigeo.tx_direccion.tx_direccion),callback:function ($$v) {_vm.$set(_vm.fields_institucion.group_datos_ubigeo.tx_direccion, "tx_direccion", $$v)},expression:"fields_institucion.group_datos_ubigeo.tx_direccion.tx_direccion"}},'v-textarea',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_institucion.group_datos_ubigeo.tx_direccion.caracteristicas.tooltip)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }