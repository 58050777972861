var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{},[_c('div',[_c('v-data-table',{staticClass:"elevation-1 mb-10",attrs:{"headers":_vm.headers,"items":_vm.fields_institucion.group_personal_contacto.json_ano_institucion_personal_contacto.json_ano_institucion_personal_contacto,"sort-by":"nu_id_padre"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Crear contacto ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('div',[_c('validation-provider',{attrs:{"name":"contactos_temp.tx_cargo_contacto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_cargo_contacto,"item-text":"tx_descripcion","item-value":"co_tipo","placeholder":"Tipo contacto","error-messages":errors,"label":"Cargo","outlined":"","disabled":!_vm.fields_institucion.isEditing},on:{"change":_vm.setSelected},model:{value:(_vm.contactos_temp.tx_cargo_contacto),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "tx_cargo_contacto", $$v)},expression:"contactos_temp.tx_cargo_contacto"}})]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"contactos_temp.tx_tipo_documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_tipo_documento,"item-text":"tx_descripcion","item-value":"co_tipo","placeholder":"Tipo documento","error-messages":errors,"label":"Tipo de documento","outlined":"","disabled":!_vm.fields_institucion.isEditing},on:{"change":_vm.setSelected},model:{value:(_vm.contactos_temp.tx_tipo_documento),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "tx_tipo_documento", $$v)},expression:"contactos_temp.tx_tipo_documento"}})]}}])})],1),_c('div',{staticClass:"flex justify-start"},[_c('div',{staticClass:"w-1/3"},[_c('validation-provider',{attrs:{"name":"contactos_temp.tx_nacionalidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_nacionalidad,"item-text":"tx_descripcion","item-value":"co_tipo","placeholder":"Tipo medio","error-messages":errors,"label":"Nacionalidad","outlined":"","disabled":!_vm.fields_institucion.isEditing},on:{"change":_vm.setSelected},model:{value:(_vm.contactos_temp.tx_nacionalidad),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "tx_nacionalidad", $$v)},expression:"contactos_temp.tx_nacionalidad"}})]}}])})],1),_c('div',{staticClass:"w-2/3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"contactos_temp.tx_documento_identidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"outlined":"","error-messages":errors,"label":"Documento de identidad","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.contactos_temp.tx_documento_identidad),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "tx_documento_identidad", $$v)},expression:"contactos_temp.tx_documento_identidad"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v("Nro de teléfono")])])],1)]),_c('div',[_c('validation-provider',{attrs:{"name":"contactos_temp.tx_nombres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"outlined":"","error-messages":errors,"label":"Nombres","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.contactos_temp.tx_nombres),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "tx_nombres", $$v)},expression:"contactos_temp.tx_nombres"}},'v-text-field',_vm.attrs,false),_vm.on))]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"contactos_temp.tx_apellidos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"outlined":"","error-messages":errors,"label":"Apellidos","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.contactos_temp.tx_apellidos),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "tx_apellidos", $$v)},expression:"contactos_temp.tx_apellidos"}},'v-text-field',_vm.attrs,false),_vm.on))]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"contactos_temp.tx_telefono_local","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"outlined":"","error-messages":errors,"label":"Teléfono local","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.contactos_temp.tx_telefono_local),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "tx_telefono_local", $$v)},expression:"contactos_temp.tx_telefono_local"}},'v-text-field',_vm.attrs,false),_vm.on))]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"contactos_temp.tx_telefono_celular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"outlined":"","error-messages":errors,"label":"Teléfono celular","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.contactos_temp.tx_telefono_celular),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "tx_telefono_celular", $$v)},expression:"contactos_temp.tx_telefono_celular"}},'v-text-field',_vm.attrs,false),_vm.on))]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"contactos_temp.tx_correo_electronico","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"outlined":"","error-messages":errors,"label":"Correo electrónico","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.contactos_temp.tx_correo_electronico),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "tx_correo_electronico", $$v)},expression:"contactos_temp.tx_correo_electronico"}},'v-text-field',_vm.attrs,false),_vm.on))]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"contactos_temp.fe_desde","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg",attrs:{"outlined":"","error-messages":errors,"label":"Activo desde","disabled":!_vm.fields_institucion.isEditing},model:{value:(_vm.contactos_temp.fe_desde),callback:function ($$v) {_vm.$set(_vm.contactos_temp, "fe_desde", $$v)},expression:"contactos_temp.fe_desde"}},'v-text-field',_vm.attrs,false),_vm.on))]}}])})],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Esta seguro de borrar este registro?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("No")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Si")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v(" No hay información ")])]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }