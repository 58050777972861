<template>
    <div class="mt-2">
        <validation-provider
        v-slot="{ errors }"
        name="fields_institucion.group_datos_ubigeo.json_pais.json_pais"
        :rules=fields_institucion.group_datos_ubigeo.json_pais.caracteristicas.required
        >
            <v-autocomplete
            v-model="fields_institucion.group_datos_ubigeo.json_pais.json_pais"
            :items="listaPaises"
            :loading="isLoading"
            :error-messages="errors"
            hide-no-data
            hide-selected
            item-text="tx_denominacion"
            item-value="nu_id_pais"
            :label=fields_institucion.group_datos_ubigeo.json_pais.caracteristicas.label
            :placeholder=fields_institucion.group_datos_ubigeo.json_pais.caracteristicas.placeholder
            prepend-icon=""
            outlined
            return-object
            :disabled=!fields_institucion.isEditing                            
            ></v-autocomplete>
        </validation-provider>
        <validation-provider
        v-slot="{ errors }"
        name="fields_institucion.group_datos_ubigeo.json_estado.json_estado"
        :rules=fields_institucion.group_datos_ubigeo.json_estado.caracteristicas.required
        >
            <v-autocomplete
            v-model="fields_institucion.group_datos_ubigeo.json_estado.json_estado"
            :items="listaEntidades"
            :loading="isLoading"
            :error-messages="errors"
            hide-no-data
            hide-selected
            item-text="tx_desc_entidad"
            item-value="tx_cod_entidad"
            :label=fields_institucion.group_datos_ubigeo.json_estado.caracteristicas.label
            :placeholder=fields_institucion.group_datos_ubigeo.json_estado.caracteristicas.placeholder
            prepend-icon=""
            return-object
            outlined
            @change="searchMunicipio"
            :disabled=!fields_institucion.isEditing                            
            ></v-autocomplete>
        </validation-provider>
            
        <validation-provider
        v-slot="{ errors }"
        name="fields_institucion.group_datos_ubigeo.json_municipio.json_municipio"
        :rules=fields_institucion.group_datos_ubigeo.json_municipio.caracteristicas.required
        >
            <v-autocomplete
            v-model="fields_institucion.group_datos_ubigeo.json_municipio.json_municipio"
            :items="codigosMunicipio"
            :loading="isLoading"
            :error-messages="errors"
            hide-no-data
            hide-selected
            item-text="tx_desc_municipio"
            item-value="tx_cod_municipio"
            :label=fields_institucion.group_datos_ubigeo.json_municipio.caracteristicas.label
            :placeholder=fields_institucion.group_datos_ubigeo.json_municipio.caracteristicas.placeholder
            prepend-icon=""
            return-object
            outlined
            @change="searchParroquia"
            :disabled=!fields_institucion.isEditing
            ></v-autocomplete>
        </validation-provider>
        <validation-provider
        v-slot="{ errors }"
        name="fields_institucion.group_datos_ubigeo.json_parroquia.json_parroquia"
        :rules=fields_institucion.group_datos_ubigeo.json_parroquia.caracteristicas.required
        >
            <v-autocomplete
            v-model="fields_institucion.group_datos_ubigeo.json_parroquia.json_parroquia"
            :items="codigosParroquia"
            :loading="isLoading"
            :error-messages="errors"
            hide-no-data
            hide-selected
            item-text="tx_desc_parroquia"
            item-value="tx_cod_parroquia"
            :label=fields_institucion.group_datos_ubigeo.json_parroquia.caracteristicas.label
            :placeholder=fields_institucion.group_datos_ubigeo.json_parroquia.caracteristicas.placeholder
            prepend-icon=""
            outlined
            return-object
            :disabled=!fields_institucion.isEditing
            ></v-autocomplete>
        </validation-provider>
        <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">

            <validation-provider
            v-slot="{ errors }"
            name="fields_institucion.group_datos_ubigeo.tx_direccion.tx_direccion"
            rules="required"
            >
                <v-textarea
                    v-model="fields_institucion.group_datos_ubigeo.tx_direccion.tx_direccion"
                    auto-grow
                    v-bind="attrs"
                    v-on="on"
                    class="input-group--focused text-lg"
                    :error-messages="errors"
                    :label=fields_institucion.group_datos_ubigeo.tx_direccion.caracteristicas.label                
                    outlined
                    :disabled=!fields_institucion.isEditing
                ></v-textarea>                      
            </validation-provider>   
        </template>
        <span>{{ fields_institucion.group_datos_ubigeo.tx_direccion.caracteristicas.tooltip  }} </span>
        </v-tooltip>                

    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {  LIST_PAIS, MUESTRA_ENTIDAD, MUESTRA_MUNICIPIO,MUESTRA_PARROQUIA  } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

    export default {
        name: 'InstitucionUbigeo',
        data() {
            return {
                isHiddenRepresentante: true,
                codigosPais: [],
                codigosEntidad: [],
                codigosMunicipio: [],
                codigosParroquia: [],
                isLoading: false,
                selected: {
                    json_estado: [],
                    json_municipio: [],
                    json_parroquia: [],
                },                
            }
        },
        props: {
            send_datos: Object
        },
        computed: {
            ...mapGetters(['fields_institucion']),
            listaPaises () {
                return this.codigosPais.map(entry => {
                    const Description = entry.tx_denominacion.length > this.descriptionLimit
                    ? entry.tx_denominacion.slice(0, this.descriptionLimit) + '...'
                    : entry.tx_denominacion

                    return Object.assign({}, entry, { Description })
                })
            },             
            listaEntidades () {
                return this.codigosEntidad.map(entry => {
                    const Description = entry.tx_desc_entidad.length > this.descriptionLimit
                    ? entry.tx_desc_entidad.slice(0, this.descriptionLimit) + '...'
                    : entry.tx_desc_entidad

                    return Object.assign({}, entry, { Description })
                })
            },             
        },
        methods: {
            searchPais(q) {
            this.$store.dispatch(LIST_PAIS, {q: q}).then(response => {
                this.codigosPais = response.rest.items
            }, error => {
                this.$store.commit(SET_ERROR, error.statusText)
            })      
            },      
            searchEntidad(q) {
            this.$store.dispatch(MUESTRA_ENTIDAD, {q: q}).then(response => {
                this.codigosEntidad = response.rest.items
            }, error => {
                this.$store.commit(SET_ERROR, error.statusText)
            })      
            },      
            searchMunicipio(value) {
                this.codigosMunicipio = []
                this.codigosParroquia = []
                this.fields_institucion.group_datos_ubigeo.json_municipio.json_municipio = []
                this.$store.dispatch(MUESTRA_MUNICIPIO, {tx_cod_entidad: value.tx_cod_entidad }).then(response => {
                    this.codigosMunicipio = response.rest.items
                }, error => {
                    this.$store.commit(SET_ERROR, error.statusText)
                })      
            },        
            searchParroquia(value) {
                console.log('Parroquia: ', value)
                this.$store.dispatch(MUESTRA_PARROQUIA, {tx_cod_entidad: value.tx_cod_entidad, tx_cod_municipio: value.tx_cod_municipio  }).then(response => {
                    this.codigosParroquia = response.rest.items
                }, error => {
                    this.$store.commit(SET_ERROR, error.statusText)
                })      
            },
            tomar_ubi_geo() {
                this.fields_institucion.group_datos_ubigeo.json_estado = this.fields_institucion.group_datos_ubigeo.select_representante.json_cod_entidad
                this.searchMunicipio(this.fields_institucion.group_datos_ubigeo.select_representante.json_cod_entidad)
                this.fields_institucion.group_datos_ubigeo.json_municipio = this.fields_institucion.group_datos_ubigeo.select_representante.json_cod_municipio
                this.searchParroquia(this.fields_institucion.group_datos_ubigeo.select_representante.json_cod_municipio)
                this.fields_institucion.group_datos_ubigeo.json_parroquia = this.fields_institucion.group_datos_ubigeo.select_representante.json_cod_parroquia
                this.fields_institucion.group_datos_ubigeo.tx_direccion = this.fields_institucion.group_datos_ubigeo.select_representante.tx_direccion
            },
        },
        mounted() {
            this.searchPais()
            this.searchEntidad()
            console.log('Ubigeo: ', this.send_datos)
            if (this.send_datos.operacion === 'EDITAR') {
                this.fields_institucion.group_datos_ubigeo.json_estado.json_estado = JSON.parse(this.send_datos.paciente["json_estado"])
                this.searchMunicipio(JSON.parse(this.send_datos.paciente["json_estado"]))
                this.fields_institucion.group_datos_ubigeo.json_municipio.json_municipio = JSON.parse(this.send_datos.paciente["json_municipio"])
                this.searchParroquia(JSON.parse(this.send_datos.paciente["json_municipio"]))
                this.fields_institucion.group_datos_ubigeo.json_parroquia.json_parroquia = JSON.parse(this.send_datos.paciente["json_parroquia"])

            }

        }
        
    }
</script>
