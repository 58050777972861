<template>
<div >
    <div class="">
        <div v-if="titulo">
            <InstitucionHeader titulo="titulo"  />        
        </div>
      <div>
          <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
            <NotificacionInterna :mensaje="mensaje" />  
            
          </div>
         <v-stepper v-model="e1">
            <v-stepper-header>
                  <v-stepper-step
                    :complete="e1 > 1"
                    step="1"
                  >
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="e1 > 2"
                    step="2"
                  >
                  </v-stepper-step>

            </v-stepper-header>
            <div class="flex justify-center mt-1 text-lg font-bold"> 
                {{vista[e1 - 1].descripcion }}
            </div>
            <div class="flex justify-center mt-1 text-xs font-bold"> 
                Los campos con el "*" son Obligatorios
            </div>
      <div v-if="send_datos.operacion === 'EDITAR'" class="flex justify-center items-center" @click="fields_indicadores.isEditing = !fields_indicadores.isEditing ">
          <div  v-if="fields_indicadores.isEditing === true" class="cursor-pointer py-2 px-6 mr-1 w-12 flex justify-center items-center bg-skin-button-muted">
              <div class="pr-2">
                  <v-icon
                  color="white"
                  >                                
                  {{ icons.mdiStopCircleOutline }}
                  </v-icon>
              </div>

              <div class="text-white">
                Cancelar edición
              </div>

          </div>  
          <div v-else   class="cursor-pointer py-2 px-6 mr-1 w-12 flex justify-center items-center bg-skin-button-accent">
              <div class="pr-2">
                  <v-icon
                  color="white"
                  >                                
                  {{ icons.mdiPencil }}
                  </v-icon>
              </div>

              <div class="text-white">
                Editar
              </div>

          </div>
      </div>            
          <v-stepper-items > 
            <div class="">
              <ValidationObserver ref="datos_basicos">
                  <v-stepper-content step="1">  
                    <DatosBasicos titulo="" @guardar_indicador_logro="return_indicadores_logro" :send_datos="send_datos"  />
                    <!-- Inicio Boton de Navegacion -->
                    <div class="flex justify-between  pt-2 pb-10">
                      <div class="">
                          <div class="flex justify-start">
                                <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Cancelar
                                </button>                          
                                <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiStopCircleOutline }}
                                                </v-icon>
                                </button>                          
                          </div>

                      </div>


                      <div class="">
                          <div class="flex justify-start">
                                <button @click="onSubmit_datos_basicos(1)" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Siguiente
                                </button>                          
                                <button @click="onSubmit_datos_basicos(1)" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiArrowRightBoldOutline }}
                                                </v-icon>
                                </button>                          
                          </div>

                      </div>
                    </div>
                    <!-- Fin Boton de Navegacion -->                    
                  </v-stepper-content>
              </ValidationObserver>

                <ValidationObserver ref="datos_contenido">
                  <v-stepper-content step="2">
                    <IndicadorLogroRegistrar titulo="" @guardar_indicador_logro="return_indicadores_logro" :send_datos="send_datos"  />
                    
                    <!-- Inicio Boton de Navegacion -->
                    <div class="flex justify-between">
                      <div class="">
                          <div class="flex justify-start">
                                <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Cancelar
                                </button>                          
                                <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiStopCircleOutline }}
                                                </v-icon>
                                </button>                          
                          </div>    


                      </div>


                      <div class="">
                          <div class="flex justify-start">
                                <button @click="anterior(2)" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Anterior
                                </button>                          
                                <button @click="anterior(2)" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiArrowLeftBoldOutline }}
                                                </v-icon>
                                </button>                          

                                <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                                    <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                        <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                          viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                        <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                          <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                            <animateTransform
                                              attributeName="transform"
                                              dur="2s"
                                              type="rotate"
                                              from="0 50 48"
                                              to="360 50 52"
                                              repeatCount="indefinite" />
                                            
                                          </circle>
                                        </svg>
                                    </div>
                                  Procesando...
                                </button>                                                        
                                <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                  Guardar
                                </button>                                                        

                                <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                                    <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                        <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                          viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                        <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                          <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                            <animateTransform
                                              attributeName="transform"
                                              dur="2s"
                                              type="rotate"
                                              from="0 50 48"
                                              to="360 50 52"
                                              repeatCount="indefinite" />
                                            
                                          </circle>
                                        </svg>
                                    </div>
                                </button>                                                        
                      

                                <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiContentSave }}
                                                </v-icon>
                                </button>                          
                          </div>

                      </div>
                    </div>
                    <!-- Fin Boton de Navegacion -->                    


                  </v-stepper-content>
                </ValidationObserver>


            </div>
          </v-stepper-items> 
        </v-stepper>
      </div>       
    </div> 

</div>
</template>
<script>
import { mapGetters } from 'vuex'

import InstitucionHeader from "@/components/comunes/HeaderModulo.vue";
import DatosBasicos from "@/components/configuracion/IndicadoresLogro/DatosBasicos/DatosBasicos";
import IndicadorLogroRegistrar from "@/components/configuracion/IndicadoresLogro/Indicadores/IndicadorLogroRegistrar";
//import InstitucionPerfil from "@/views/configuracion/Seguridad/Institucion//InstitucionPerfil";

import NotificacionInterna from "@/components/comunes/NotificacionInterna";

import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus,
  mdiArrowRightBoldOutline,
  mdiArrowLeftBoldOutline,
  mdiStopCircleOutline,
  mdiContentSave,
  mdiLoading

} from '@mdi/js';
import { reactive  } from "@vue/composition-api"

export default {
    name: 'WizardAcademico',
    data() {
        return {
          usuario_temp: {
            fields_indicadores: '',
          },
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
            e1: 1,
            isLoading: false,
            mostrar_vista: {
                id: '1',
                descripcion: 'Datos Básicos'
                }, 
            vista: [
                {
                id: '1',
                descripcion: 'Datos Básicos'
                },
                {
                id: '2',
                descripcion: 'Literales'
                },

            ], 
            selected: {
                json_estado_co: [],
                json_municipio_co: [],
                json_parroquia_co: [],
            },            

 //           titulo: "Programar "
        }
    },
    props: {
        send_datos: Object,
        titulo: String
    },
    components: {
      DatosBasicos,
      IndicadorLogroRegistrar,      
      InstitucionHeader,
      //UsuarioPerfil,
     NotificacionInterna
    },
    computed: {
    ...mapGetters(['fields_indicadores']),
        notificar() {
        return this.noti;
        },
    },
    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      },    
    },    
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus,
            mdiArrowRightBoldOutline,
            mdiArrowLeftBoldOutline,
            mdiStopCircleOutline,
            mdiContentSave,
            mdiLoading
        });  


        return {
        icons
        }
    },
    methods: {        
        return_indicadores_logro(msg) {
          console.log("return_indicadores_logro: ", msg)
            //this.buscarPersona('params')
            var guardar_datos_basicos = JSON.parse(msg);            
            this.$emit("guardar_indicador_logro", JSON.stringify(guardar_datos_basicos));
        },        
        async onSubmit_datos_basicos (step) {
            const isValid = await this.$refs.datos_basicos.validate()
            if (isValid) {
                this.mostrar_vista = this.vista[step]
                this.e1 = this.e1 + 1
            }
        },
        async onSubmit_ubigeo (step) {
          console.log("onSubmit_ubigeo: ", step)
            const isValid = await this.$refs.datos_ubigeo.validate()
            if (isValid) {
                this.mostrar_vista = this.vista[step]
                this.e1 = this.e1 + 1
            }
        },
        anterior(step) {
            this.e1 = step - 1
        },
        async cancelar () {
            this.mensaje.nosatisfactorio.status = false

              this.mensaje.nosatisfactorio.texto = "Operación cancelada"
              this.mensaje.nosatisfactorio.status = true
                  
                  setTimeout(() => {
                        //this.isLoading = false
                        //this.$emit("actualizar_datos_institucion", JSON.stringify(this.fields_indicadores));
                        //this.mensaje.nosatisfactorio.status = false
                    },3000)                  
        },        
        async guardar () {
            // for (const property in this.fields_indicadores) {
            //       const myArray = property.split("_"); 
            //       if (myArray[0] === 'group') {
            //           for (const property_01 in this.fields_indicadores[property]) {
            //               console.log("Datos a guardar: ", property, property_01, ' ' , this.fields_indicadores[property][property_01][property_01])             

            //               //this.fields_usuario[property][property_01][property_01] = ''
            //           }
            //       }
            // }          

            var isValid = false
            const wizard = this.fields_indicadores
            for (const property in wizard) {
                if (wizard[property].json_indicadores_logro) {
                  isValid = true
                }
            }
            if (!isValid) {                    
              
                return true
            } else {
                this.$emit("actualizar_datos_indicadores", JSON.stringify(this.fields_indicadores));              

            }
           // this.isLoading = true

            
// desde

// hasta
            this.e1 =  1


        }, 
        async limpiar () {
            for (const property in this.fields_indicadores) {

                console.log('property: ', property)
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_indicadores[property]) {
                          this.fields_indicadores[property][property_01][property_01] = ''
                      }
                  }
            }

        },
              
    },
    mounted() {
        this.send_datos.operacion = 'REGISTRAR'
        this.fields_indicadores.group_datos_basicos.nu_id_institucion.nu_id_institucion = this.send_datos.institucion.nu_id_institucion
        if (this.send_datos.operacion === 'REGISTRAR') {
          //this.limpiar()
          this.fields_indicadores.isEditing = true
        } else {
          this.fields_indicadores.isEditing = false
        }      
    },         

  
}
</script>