<template>
  <div class="">
        <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
          <NotificacionInterna :mensaje="mensaje" />  
        </div>
      <div>
        <EscalaValoresHeader :titulo=titulo /> 
      </div>  

        <div class="">
            <div>
                <v-data-table
                  :headers="headers"
                  :items="fields_calificaciones.group_escala_valores.json_group_escala_valores.json_group_escala_valores"
                  sort-by="calories"
                  class="elevation-1 mb-10"

                >
                
                  <!-- <template v-slot:item.json_perfil="{ item }">
                      <div v-for="(item_esp, index) in item.json_perfil" :key="index">

                      {{ item_esp.tx_nombre_perfil }}
                      </div>
                  </template>      -->
                  <!--                   
                  <template v-slot:item.nu_valor_minimo_escala="{ item }">
                      {{ item.nu_valor_minimo_escala.tx_razon_social }}
                  </template>      -->
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                    Crear
                                </v-btn>

                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                      >
                                          <v-tooltip right>
                                            <template v-slot:activator="{ on, attrs }">

                                            <validation-provider
                                              v-slot="{ errors }"
                                              name="fields_calificaciones.group_escala_valores.co_escala_valores.co_escala_valores"
                                              :rules=fields_calificaciones.group_escala_valores.co_escala_valores.caracteristicas.required
                                            >
                                              <v-text-field
                                                v-model="fields_calificaciones.group_escala_valores.co_escala_valores.co_escala_valores"
                                                :label=fields_calificaciones.group_escala_valores.co_escala_valores.caracteristicas.label
                                                outlined
                                                class="input-group--focused text-lg pt-2"
                                                :error-messages="errors"
                                                v-bind="attrs"
                                                v-on="on"
                                                :disabled="!fields_calificaciones.isEditing"
                                              ></v-text-field>
                                            </validation-provider>     
                                            </template>
                                            <span>{{ fields_calificaciones.group_escala_valores.co_escala_valores.caracteristicas.tooltip  }} </span>
                                          </v-tooltip>                

                                      </v-col>
                                      
                                      <v-col
                                        cols="12"
                                      >
                                          <v-tooltip right>
                                            <template v-slot:activator="{ on, attrs }">

                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="fields_calificaciones.group_escala_valores.tx_denominacion_escala_valores.tx_denominacion_escala_valores"
                                            :rules=fields_calificaciones.group_escala_valores.tx_denominacion_escala_valores.caracteristicas.required
                                          >
                                          <v-text-field
                                            v-model="fields_calificaciones.group_escala_valores.tx_denominacion_escala_valores.tx_denominacion_escala_valores"
                                            :label=fields_calificaciones.group_escala_valores.tx_denominacion_escala_valores.caracteristicas.label
                                            outlined
                                            class="input-group--focused text-lg pt-2"
                                            :error-messages="errors"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!fields_calificaciones.isEditing"
                                          ></v-text-field>
                                          </validation-provider>     
                                            </template>
                                            <span>{{ fields_calificaciones.group_escala_valores.tx_denominacion_escala_valores.caracteristicas.tooltip  }} </span>
                                          </v-tooltip>                
                                      </v-col>

                                      <v-col
                                        cols="12"
                                      >
                                      <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_calificaciones.group_escala_valores.nu_valor_minimo_escala.nu_valor_minimo_escala"
                                          :rules=fields_calificaciones.group_escala_valores.nu_valor_minimo_escala.caracteristicas.required
                                        >
                                          <v-text-field
                                            :label=fields_calificaciones.group_escala_valores.nu_valor_minimo_escala.caracteristicas.label
                                            v-model="fields_calificaciones.group_escala_valores.nu_valor_minimo_escala.nu_valor_minimo_escala"
                                            outlined
                                            class="input-group--focused text-lg pt-2"
                                            :error-messages="errors"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!fields_calificaciones.isEditing"
                                          ></v-text-field>
                                        </validation-provider>                    
                                        </template>
                                        <span>{{fields_calificaciones.group_escala_valores.nu_valor_minimo_escala.caracteristicas.tooltip  }} </span>
                                      </v-tooltip>                
                                      </v-col>
                                      <v-col
                                        cols="12"
                                      >
                                      <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_calificaciones.group_escala_valores.nu_valor_maximo_escala.nu_valor_maximo_escala"
                                          :rules=fields_calificaciones.group_escala_valores.nu_valor_maximo_escala.caracteristicas.required
                                        >
                                          <v-text-field
                                            :label=fields_calificaciones.group_escala_valores.nu_valor_maximo_escala.caracteristicas.label
                                            v-model="fields_calificaciones.group_escala_valores.nu_valor_maximo_escala.nu_valor_maximo_escala"
                                            outlined
                                            class="input-group--focused text-lg pt-2"
                                            :error-messages="errors"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!fields_calificaciones.isEditing"
                                          ></v-text-field>
                                        </validation-provider>                    
                                        </template>
                                        <span>{{fields_calificaciones.group_escala_valores.nu_valor_maximo_escala.caracteristicas.tooltip  }} </span>
                                      </v-tooltip>                
                                      </v-col>
                                      <v-col
                                        cols="12"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_calificaciones.group_escala_valores.co_estatus.co_estatus"
                                          :rules=fields_calificaciones.group_escala_valores.co_estatus.caracteristicas.required
                                        >
                                          <v-select
                                            v-model="fields_calificaciones.group_escala_valores.co_estatus.co_estatus"
                                            :items="list_estatus"
                                            item-value="co_estatus"
                                            item-text="tx_denominacion_estatus"
                                            class="input-group--focused text-lg"
                                            :placeholder=fields_calificaciones.group_escala_valores.co_estatus.caracteristicas.placeholder
                                            :error-messages="errors"
                                            :label=fields_calificaciones.group_escala_valores.co_estatus.caracteristicas.label
                                            outlined
                                            :disabled=!fields_calificaciones.isEditing
                                          ></v-select>                      
                                        </validation-provider>                    
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Guardar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Desea borrar este registro?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Guardar</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      {{ icons.mdiPencil }}                                            
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDelete }}                                            
                      
                    </v-icon>

                  </template>
                  <template v-slot:no-data>
                    <span>
                      No hay información
                    </span>
                  </template>
              </v-data-table>
            </div>
            <div class="text-center">
            <button @click="cerrar()" class="text-center w-full font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-muted-hover cursor-pointer py-2 px-6 mr-1">
              Cerrar
            </button>                                                        
            </div>

        </div>                  
       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import EscalaValoresHeader from "@/components/comunes/HeaderModulo.vue";

import {  CREATE_ESCALA_VALORES, LIST_ESCALA_VALORES } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'EscalaValores',
  data () {
    return {
      list_anos: [],
        modal_dialogo_inicial:false,
        modal_dialogo_final:false,
        mensaje: {
          satisfactorio: {
            status: false,
            texto: ''
          },
          nosatisfactorio: {
            status: false,
            texto: ''
          },
        },
        list_estatus: [
          {
            co_estatus: 'A',
            tx_denominacion_estatus: 'Activo'
          },
          {
            co_estatus: 'I',
            tx_denominacion_estatus: 'Inactivo'
          },
          
        ],
        list_perfiles: [],
        fields_calificaciones_temp: [],
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          nu_valor_minimo_escala: '',
          json_perfil: '',
        },  

        headers: [
            {
              text: 'Código',
              align: 'start',
              sortable: false,
              value: 'co_escala_valores',
            },
            { text: 'Denominacion', value: 'tx_denominacion_escala_valores' },        
            { text: 'Valor mínimo', value: 'nu_valor_minimo_escala' },        
            { text: 'Valor máximo', value: 'nu_valor_maximo_escala' },        
            { text: 'Estatus', value: 'co_estatus' },        
            { text: 'Actions', value: 'actions', sortable: false },
        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_calificaciones']),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  props: {
    send_datos: Object,
    titulo: String
  },
  components: {
    NotificacionInterna,
    EscalaValoresHeader
  },    
  methods: {
      cerrar() {
      this.$emit("guardar_escala_valores", JSON.stringify(this.fields_calificaciones));
      },
      searchEscalaValores(q) {
        this.$store.dispatch(LIST_ESCALA_VALORES, {nu_id_ano_escolar: q}).then(response => {
            this.fields_calificaciones.group_escala_valores.json_group_escala_valores.json_group_escala_valores = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })
      },
      fecha_cita_min() {
      //const [month, day, year] = date.split('/')
      //return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`

        //return new Date().toISOString().slice(0,10);
      },    
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.nu_valor_minimo_escala = Object.assign({}, this.fields_calificaciones.group_escala_valores.nu_valor_minimo_escala.nu_valor_minimo_escala)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.fields_calificaciones.group_escala_valores.nu_valor_minimo_escala.nu_valor_minimo_escala[this.editedIndex], this.escala_valores_perfil)
        } else {
          console.log('this.this.send_datos: ', this.send_datos)
          this.fields_calificaciones.group_escala_valores.nu_id_institucion.nu_id_institucion =  this.send_datos.institucion.nu_id_institucion
          this.fields_calificaciones.group_escala_valores.json_group_escala_valores.json_group_escala_valores.push({            
            nu_id_institucion: this.send_datos.institucion.nu_id_institucion,
            co_escala_valores: this.fields_calificaciones.group_escala_valores.co_escala_valores.co_escala_valores,
            tx_denominacion_escala_valores: this.fields_calificaciones.group_escala_valores.tx_denominacion_escala_valores.tx_denominacion_escala_valores,
            nu_valor_minimo_escala: this.fields_calificaciones.group_escala_valores.nu_valor_minimo_escala.nu_valor_minimo_escala,
            nu_valor_maximo_escala: this.fields_calificaciones.group_escala_valores.nu_valor_maximo_escala.nu_valor_maximo_escala,
            co_estatus: this.fields_calificaciones.group_escala_valores.co_estatus.co_estatus,
          })

        }
        this.return_escala_valores(JSON.stringify(this.fields_calificaciones))
        this.close()
      },    
      initialize () {
        this.escala_valores_perfil = this.defaultItem
      },

      editItem (item) {
        this.editedIndex = this.fields_calificaciones.group_escala_valores.nu_valor_minimo_escala.nu_valor_minimo_escala.indexOf(item)
        this.escala_valores_perfil = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.fields_calificaciones.group_escala_valores.json_group_escala_valores.json_group_escala_valores.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_calificaciones.group_escala_valores.json_group_escala_valores.json_group_escala_valores.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    remove (item) {
      var index = this.escala_valores_perfil.json_perfil.findIndex(i => i.nu_id_perfil === item.nu_id_perfil);
      if (index >= 0) this.escala_valores_perfil.json_perfil.splice(index, 1)
    },    

    // Paso #2
    return_escala_valores(msg) {
        //this.buscarPersona('params')
        var guardar = JSON.parse(msg);

        const formData = new FormData()
        formData.append('escala_valores', JSON.stringify(guardar))

    // desde aca optimizar
        this.mensaje.satisfactorio.status = false
        this.mensaje.nosatisfactorio.status = false

        //formData = JSON.parse(JSON.stringify(guardar.formData))

        formData.append('oper', 'REGISTRO')

        this.$store.dispatch(CREATE_ESCALA_VALORES, formData).then(datos => { 
              this.mensaje.satisfactorio.texto = "Registro  satisfactorio"
              this.mensaje.satisfactorio.status = true
              
              setTimeout(() => {
                    this.isLoading = false
                //    this.$emit("guardar_usuario", JSON.stringify(this.fields_escala_valores));
                    console.log('message emit from child component01: ', datos)
                    this.mensaje.satisfactorio.status = false
                    this.$notify({
                      group: 'generic',
                      type: 'success',
                      title: 'Mensaje importante',
                      classes: 'w-full text-lg',
                      text: 'Datos  creado satisfactoriamente!'
                    });      

                },3000)                  
                
                this.$emit("actualizar_datos_escala_valores", JSON.stringify(guardar));              
                
                //this.$refs.tabs_ubigeo_tabJustify.click();

              
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
              this.mensaje.nosatisfactorio.texto = "Registro de escala_valores cancelado"
              this.mensaje.nosatisfactorio.status = true
              this.ocultar_pestana()
              this.mostrar_pestana('collapseMotivo')
              setTimeout(() => {
                  this.mensaje.nosatisfactorio.status = false
                  },3000);             
              console.log("Cancelar: " + guardar.btn_guardar);

          })  

      /// hasta aca optimizar
        
    },    


  },
  
  mounted() {
    //this.searchAnoEscolar();
    this.searchEscalaValores()
    if (this.send_datos.operacion === 'EDITAR') {
      //console.log('nu_valor_minimo_escala: ', JSON.parse(this.fields_calificaciones.group_escala_valores.nu_valor_minimo_escala.nu_valor_minimo_escala))
        //this.fields_calificaciones.group_escala_valores.nu_valor_minimo_escala.nu_valor_minimo_escala = JSON.parse(this.send_datos.usuario.nu_valor_minimo_escala)
        //this.fields_calificaciones.group_escala_valores.json_perfil.json_perfil = JSON.parse(this.send_datos.usuario.json_perfil)
          // for (var prop in this.send_datos.usuario) {
          //     if(typeof this.fields_calificaciones[prop] === 'object') {
          //           if (this.fields_calificaciones[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_calificaciones[prop][prop] = JSON.parse(this.send_datos.usuario[prop])
          //           } else {
          //             this.fields_calificaciones[prop][prop] = this.send_datos.usuario[prop]
          //           }
          //     } else {
          //           this.fields_calificaciones[prop][prop] = this.send_datos.usuario[prop]
          //     }
          // }
    } else {
        //this.limpiar()
        //this.fields_calificaciones.group_escala_valores.json_perfil.json_perfil = []
        this.fields_calificaciones.isEditing = true
    }           
  },
    
}
</script>