 <template>
  <div class="w-full p-2">

      <div>
        <InstitucionOpciones @actualizar_datos_institucion="return_opciones" :send_datos="send_datos"></InstitucionOpciones>
      </div>

  </div>
</template>

<script>
import InstitucionOpciones from "@/views/configuracion/Institucion/InstitucionOpciones.vue";

import {  SET_ERROR } from '@/store/mutations.type'
import { LIST_INSTITUCION } from '@/store/actions.type'

export default {
  name: 'Institucion',
  data () {
    return {
      titulo: 'Academia',
      datos_academia: [],
      send_datos: {
        list_instituciones: []
      },

    }
  },
  setup() {
  },  
  computed: {
  },
  components: {
    InstitucionOpciones
  //  ThemifyIcon    
  },  
  watch: {

  },  
  methods: {
      searchInstituciones(q) {
        this.$store.dispatch(LIST_INSTITUCION, {q: q}).then(response => {
            this.send_datos.list_instituciones = JSON.parse(response.rest.items)
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })      
      },

    return_opciones(msg) {
        console.log("return_opciiones: " ,msg);
      this.searchInstituciones({q: msg})

      //this.send_academia = msg;
    },      
    return_consulta_medica(msg) {
        //this.buscarPersona('params')
        var guardar_academia = msg;
        //this.select_academia = JSON.parse(msg);
        console.log("return_consulta_medica guardar_academia update: " , guardar_academia);

        
        if (guardar_academia.btn_guardar == 'true') {
            this.searchAcademia({})
            //this.fields.academia = this.select_academia.nu_id_representante
            console.log("Guarda ultima medica: " + guardar_academia.btn_guardar);
        } else {
            console.log("Cancelar: " + guardar_academia.btn_guardar);
        }
        this.dialogConsultaMedica = false
        //this.send_academia = msg;
    },        

  },
  mounted () {
          this.$store.dispatch(LIST_INSTITUCION, {}).then(response => {
              this.send_datos.list_instituciones = response.rest.items
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          })      
    /*  this.$store.dispatch(READ_ESPECIALIDAD, {}).then(response => {

          this.datos_academia = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
*/

  },
  updated() {
    console.log('updated Persona: ')
    //this.refrescar()
  }

}
</script>
