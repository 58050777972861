<template>
    <div>
        <div class="pl-2 pr-2">
            <div class="pl-4 pt-2 pr-2  mb-2 rounded-lg bg-skin-search text-black" >
              <v-text-field
                v-model="search"
                clearable
                :prepend-inner-icon=icons.mdiMagnify
                label="Buscador"
              ></v-text-field>
              </div>
              <div class="rounded-lg">

                  <v-data-table
                    :headers="headers"
                    :items="send_datos.list_indicadores_logro"
                    :items-per-page="5"
                    :search="search"        
                    class="elevation-1"
                  >
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="indicadores_logro(item)"
                        >
                          {{ icons.mdiBookPlusMultiple }}
                        </v-icon>
                        <v-icon
                          small
                          class="mr-2"
                          @click="editar(item)"
                        >
                          {{ icons.mdiPencil }}        
                        </v-icon>
        <!--                <v-icon
                          small
                          @click="mostrar_foto(getImgUrl(JSON.parse(item.tx_fotos_json)))"
                        >
                          {{ icons.mdiImageSearchOutline }}                
                        </v-icon>
        -->                
                      </template>
                        <!-- name -->
                        <template #[`item.json_indicadores_logro`]="{item}">
                          <div class="d-flex flex-column">
                              <div class="" v-for="(item_01, index) in JSON.parse(item.json_indicadores_logro)" :key="index">   
                                   
                                <span class="text-xs"> {{ item_01.tx_nombre_literal }} </span>
                                <span class="text-xs"> [{{ item_01.nu_valor_minimo }} : {{ item_01.nu_valor_maximo }} ] </span>
                              </div>
                            <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.tx_razon_social }}</span>
            <!--                <small>{{ item.tx_nombres }}</small> -->
                          </div>
                        </template>          
                        
                  </v-data-table>
              </div>
    </div>

        <v-dialog
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition" 
            v-if="dialogLiteral" :value="true" @input="dialogLiteral = false"                   
            >
            <v-card>
              <v-container>

                <!--<div  class="md:p-2 flex justify-between text-skin-base bg-gray-50">
                    <div class="p-2 text-sm md:text-lg">                        
                        <span>Historia clínica: </span><span><strong>{{ fields_list_indicadores_logro.group_list_indicadores_logro.tx_list_indicadores_logro.tx_list_indicadores_logro }}</strong>  </span>                                                
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Paciente: <span><strong>{{ fields_paciente.tx_apellidos.tx_apellidos}} {{ fields_paciente.tx_nombres.tx_nombres}}</strong>  </span>                        
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Fecha de apertura: <span><strong>{{ fields_list_indicadores_logro.group_list_indicadores_logro.fe_apertura.fe_apertura }}</strong>  </span>
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Apeturada por: <span><strong>{{ currentUser.username }}</strong>  </span>
                    </div>        

                </div>                
-->
                <div class="w-full mx-auto my-auto">

                </div>

              </v-container>

            </v-card>
          </v-dialog>              

    </div>
</template>

<script>
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { mapGetters } from 'vuex'
import { reactive  } from "@vue/composition-api"
//import {   SET_ERROR } from '@/store/mutations.type'
//import { LIST_INDICADORES_LOGRO } from '@/store/actions.type'
//import { SET_ERROR } from '@/store/mutations.type'


export default {
    name: 'IndicadorLogroListar',
    data() {
        return {
            dialogLiteral: false, 
            list_indicadores_logro: [],
            search: '',
            titulo: 'Indicadores de Logro',
            headers: [
              {
                text: 'Código indicador',
                align: 'start',
                sortable: false,
                value: 'co_indicador_logro',
              },          
              {
                text: 'Nombre del indicador',
                sortable: true,
                value: 'tx_nombre_indicador_logro',
              },          
              { 
                text: 'Literales', 
                value: 'json_indicadores_logro' 
              },
              { 
                text: 'Acción', 
                value: 'actions', 
                sortable: false 
              },          
            ],            
        }
    },
    props: {
        send_datos: Object,
    },
    computed: {
        ...mapGetters(['fields_institucion']),
    },    
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  

        return {
        icons,
        }
    },
    methods: {
    indicadores_logro (id) {
      console.log('Editar id: ', id) // someValue
      this.dialogLiteral = true
    },
    editar (id) {
      console.log('Editar id: ', id) // someValue
      this.send_datos.list_indicadores_logro = id
      this.send_datos.operacion = 'EDITAR'
      this.dialogLiteral = true
    },
      
    }, 
    components: {
    },
    mounted() {
    },
    watch: {
    },    


}
</script>