var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('ValidationObserver',{ref:"datos_basicos"},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_indicadores.group_datos_basicos.co_indicador_logro.co_indicador_logro","rules":_vm.fields_indicadores.group_datos_basicos.co_indicador_logro.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"label":_vm.fields_indicadores.group_datos_basicos.co_indicador_logro.caracteristicas.label,"outlined":"","error-messages":errors,"disabled":!_vm.fields_indicadores.isEditing},model:{value:(_vm.fields_indicadores.group_datos_basicos.co_indicador_logro.co_indicador_logro),callback:function ($$v) {_vm.$set(_vm.fields_indicadores.group_datos_basicos.co_indicador_logro, "co_indicador_logro", $$v)},expression:"fields_indicadores.group_datos_basicos.co_indicador_logro.co_indicador_logro"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_indicadores.group_datos_basicos.co_indicador_logro.caracteristicas.tooltip)+" ")])])],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_indicadores.group_datos_basicos.tx_nombre_indicador_logro.tx_nombre_indicador_logro","rules":_vm.fields_indicadores.group_datos_basicos.tx_nombre_indicador_logro.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"label":_vm.fields_indicadores.group_datos_basicos.tx_nombre_indicador_logro.caracteristicas.label,"outlined":"","error-messages":errors,"disabled":!_vm.fields_indicadores.isEditing},model:{value:(_vm.fields_indicadores.group_datos_basicos.tx_nombre_indicador_logro.tx_nombre_indicador_logro),callback:function ($$v) {_vm.$set(_vm.fields_indicadores.group_datos_basicos.tx_nombre_indicador_logro, "tx_nombre_indicador_logro", $$v)},expression:"fields_indicadores.group_datos_basicos.tx_nombre_indicador_logro.tx_nombre_indicador_logro"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_indicadores.group_datos_basicos.tx_nombre_indicador_logro.caracteristicas.tooltip)+" ")])])],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_indicadores.group_datos_basicos.tx_descripcion_indicador_logro.tx_descripcion_indicador_logro","rules":_vm.fields_indicadores.group_datos_basicos.tx_descripcion_indicador_logro.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"outlined":"","error-messages":errors,"label":_vm.fields_indicadores.group_datos_basicos.tx_descripcion_indicador_logro.caracteristicas.label,"disabled":!_vm.fields_indicadores.isEditing},model:{value:(_vm.fields_indicadores.group_datos_basicos.tx_descripcion_indicador_logro.tx_descripcion_indicador_logro),callback:function ($$v) {_vm.$set(_vm.fields_indicadores.group_datos_basicos.tx_descripcion_indicador_logro, "tx_descripcion_indicador_logro", $$v)},expression:"fields_indicadores.group_datos_basicos.tx_descripcion_indicador_logro.tx_descripcion_indicador_logro"}},'v-textarea',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_indicadores.group_datos_basicos.tx_nombre_indicador_logro.caracteristicas.tooltip)+" ")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }